import { useEffect, useState } from 'react'
import Header from '../../components/Header'
import dataService from '../../services/data.service'
import { Container, DataTable, Wrap, Icon, FilterBar, SearchBar, TableTitle, Horizontal, LogContent, DateInput, EmailCard, EmailCardItem, EmailCardTopBar, EmailCardActionBar, EmailCardActionSendButton } from './styles'
import { IoChevronDown, IoChevronUp, IoSend, IoClose, IoPerson, IoMail, IoPeople, IoPaperPlane, IoCheckmarkDoneSharp } from 'react-icons/io5'
import theme from '../../Contants/theme'
import moment, { Moment } from 'moment'
import LoadingSpinner from '../../components/LoadingSpinner'
import { stringify } from 'querystring'

const Logs = () => {
  const [isActive, setIsActive] = useState(false)
  const [data, setData] = useState([])
  const [initialData, setInitialData] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [isEmailOpen, setIsEmailOpen] = useState(false)
  const [isCurrentActive, setCurrentActive] = useState<number | null>(null)
  const [isCurrentEmailActive, setCurrentEmailActive] = useState<number | null>(null)
  const [emailList, setEmailList] = useState([])
  const [search, setSearch] = useState('')
  const [dateInit, setDateInit] = useState<Moment>(moment().subtract(1, 'month'))
  const [dateFinal, setDateFinal] = useState<Moment>(moment())
  const [sendEmailInfo, setSendEmailInfo] = useState<any>()
  const [isSending, setIsSending] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const [groups, setGroups] = useState<any[]>([])
  const [emailSection, setEmailSection] = useState<string>('group')

  useEffect(() => {
    dataService.getAllLogs()
      .then((result) => {
        setInitialData(result)
        setData(result)
      }).catch(error => { return error })

    dataService.getAllGroups()
      .then((result) => {
        setGroups(result)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    if (data) {
      // eslint-disable-next-line @typescript-eslint/prefer-includes
      const categoriesFilter = data.filter(item => Object.values(item).toString().toLowerCase().indexOf(search.toLowerCase()) > -1)
      categoriesFilter.length === 0 || search === '' || categoriesFilter.length === -1 ? setData(initialData) : setData(categoriesFilter)
    }
  }, [search])

  interface Idata {
    createdAT: string
    updatedAt: string
    id: number
    job: string
    status: string
    responsable: string
    content: string
  }

  useEffect(() => {
    // if (data && dateInit && dateFinal) {
    //   // eslint-disable-next-line @typescript-eslint/prefer-includes
    //   const dateFilter = data.filter((item: Idata) => {
    //     const itemDate = moment(item.createdAT).format('DD/MM/YYYY')
    //     const condition = moment(itemDate).isBetween(dateInit, dateFinal) || moment(itemDate).isSame(dateInit) || moment(itemDate).isSame(dateFinal)
    //     return condition
    //   })
    //   dateFilter.length === 0 || dateFilter.length === -1 ? setData(initialData) : setData(dateFilter)
    // }

    if (data && dateInit && dateFinal) {
      const dateFilter = data.filter((item: Idata) => {
        const itemDate = moment(item.createdAT, 'DD/MM/YYYY')
        const condition = itemDate.isBetween(dateInit, dateFinal) ||
          itemDate.isSame(dateInit) ||
          itemDate.isSame(dateFinal)
        return condition
      })
      dateFilter.length === 0 || dateFilter.length === -1
        ? setData(initialData)
        : setData(dateFilter)
    }
  }, [data, dateInit, dateFinal])

  // if (data) {
  //   const filteredData = data.filter((item: Idata) => {
  //     const date = moment(item.createdAT)
  //     return moment(date).isBetween(dateInit, dateFinal, null, '[]')
  //   })
  //   setData(filteredData)
  // }

  const formatDate = (date: any) => {
    return moment(date)
  }

  useEffect(() => {
    dataService.getAllUsers()
      .then((result) => {
        setEmailList(result)
      }).catch((error) => {
        return error
      })
  }, [])

  const getCurrentItem = (state: boolean) => {
    setIsOpen(state)
  }

  const getCurrentEmail = (state: boolean) => {
    setIsEmailOpen(state)
  }

  interface IndividualData {
    email: string
    name: string
    content: {
      JOB?: string
      STATUS?: string
      DATETIME?: string
      CONTENT?: any
      DESCRIPTION?: any
    }
  }

  const handleSendEmail = (data: IndividualData) => {
    setIsSending(true)
    setSendEmailInfo({ data })
    dataService.sendIndividualEmail(data)
      .then((res) => {
        if (res.status === 400) {
          setEmailSent(false)
          setIsSending(false)
        } else {
          setIsSending(false)
          setEmailSent(true)
          console.log('cheguei aqui')
        }
      }).catch(error => console.log(error))
  }

  return (
      <div>
      <Header content={
        <Container>
        <Wrap>
          <FilterBar>
            <TableTitle>Logs de ocorrência</TableTitle>
            <Horizontal style={{ width: '70%', justifyContent: 'flex-end', gap: 50, alignItems: 'center' }}>
              <Horizontal style={{ alignItems: 'center', fontSize: 14 }}>
                Selecionar Periodo:
                <DateInput name={'dateInit'} onChange={(e: any) => setDateInit(formatDate(e.target.value))}/>
                <DateInput name={'dateFinal'} onChange={(e: any) => setDateFinal(formatDate(e.target.value))}/>
              </Horizontal>
              <SearchBar
              placeholder={'Pesquisar'}
              onChange={(e: any) => setSearch(e.target.value)}
              />
            </Horizontal>
          </FilterBar>
        <DataTable>
            <thead>
              <tr>
                <th>Log</th>
                <th>Job</th>
                <th>Descrição</th>
                <th>Status</th>
                <th>Responsável</th>
                <th>Data / Hora</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item: any, index) => {
                const content = {
                  info: '',
                  warning: '',
                  error: ''
                }
                let format
                if (item.content) {
                  format = JSON.parse(item.content)
                  // content.info = format.info.Leads
                  // content.warning = '',
                  // content.error = format.error.Leads
                }
                return (
                  <>
                    <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.job}</td>
                    <td>{item.description}</td>
                    <td style={{ color: item.status === 'Failed' ? theme.COLORS.ERROR_700 : theme.COLORS.CONCLUDED_800 }}>{item.status}</td>
                    <td>{item.responsable}</td>
                    <td>{moment(item.createdAT).format('DD/MM/YYYY hh:mm:ss')}</td>
                    <td style={{ position: 'relative' }}>
                    {isEmailOpen && isCurrentEmailActive === index
                      ? <>
                        <Icon onClick={() => { setCurrentEmailActive(null); getCurrentEmail(false) }}>
                          <IoSend />
                        </Icon>
                        <EmailCard>
                          <EmailCardTopBar>
                            <h4>Enviar Email</h4>
                            <Icon onClick={() => { setCurrentEmailActive(null); getCurrentEmail(false) }}>
                              <IoClose />
                            </Icon>
                           </EmailCardTopBar>
                            <Horizontal style={{ justifyContent: 'center', paddingTop: 20, paddingBottom: 20 }}>
                             <SearchBar style={{ width: '90%' }} placeholder={'Pesquisar'} />
                            </Horizontal>
                            <Horizontal style={{ justifyContent: 'space-evenly', paddingTop: 5, paddingBottom: 20 }}>
                              <div style={ emailSection === 'group' ? { borderBottom: '4px solid white', paddingBottom: 10 } : { }} onClick={() => { setEmailSection('group') }}>
                                <IoPeople/>
                                Grupos ({groups.length})
                              </div>
                              <div style={ emailSection === 'users' ? { borderBottom: '4px solid white', paddingBottom: 10 } : { }} onClick={() => { setEmailSection('users') }}>
                                <IoPerson/>
                                Pessoas ({emailList.length})
                              </div>
                             </Horizontal>
                          {
                            emailSection === 'group'
                              ? groups.map((group: any, emailIndex) => {
                                return (
                                  <EmailCardItem key={emailIndex}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <div>
                                        <IoPerson/> {group.name}
                                      </div>
                                      <div>
                                        <IoMail/> {group.permissions}
                                      </div>
                                    </div>
                                    {
                                     sendEmailInfo && sendEmailInfo.index === emailIndex
                                       ? isSending && !emailSent
                                         ? <Icon>
                                        <LoadingSpinner/>
                                      </Icon>
                                         : !isSending && emailSent
                                             ? <Icon>
                                        <IoCheckmarkDoneSharp />
                                      </Icon>
                                             : null
                                       : <Icon onClick={() => {}}>
                                      <IoPaperPlane />
                                    </Icon>
                                    }
                                  </EmailCardItem>
                                )
                              })

                              : emailList.map((email: any, emailIndex) => {
                                return (
                                <EmailCardItem key={emailIndex}>
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div>
                                      <IoPerson/> {email.name}
                                    </div>
                                    <div>
                                      <IoMail/> {email.email}
                                    </div>
                                  </div>
                                  {
                                   sendEmailInfo && sendEmailInfo.index === emailIndex
                                     ? isSending && !emailSent
                                       ? <Icon>
                                      <LoadingSpinner/>
                                    </Icon>
                                       : !isSending && emailSent
                                           ? <Icon>
                                      <IoCheckmarkDoneSharp />
                                    </Icon>
                                           : null
                                     : <Icon onClick={() => {
                                       const dataSend: IndividualData = {
                                         email: email.email,
                                         name: email.name,
                                         content: {
                                           JOB: item.job,
                                           STATUS: item.status,
                                           DATETIME: item.createdAT,
                                           CONTENT: item.content,
                                           DESCRIPTION: item.description
                                         }
                                       }
                                       handleSendEmail(dataSend)
                                     }}>
                                    <IoPaperPlane />
                                  </Icon>
                                  }
                                </EmailCardItem>
                                )
                              })
                          }
                          <EmailCardActionBar>
                            <EmailCardActionSendButton>
                              Enviar <IoSend/>
                            </EmailCardActionSendButton>
                          </EmailCardActionBar>
                        </EmailCard>
                      </>
                      : <Icon onClick={() => { setCurrentEmailActive(index); getCurrentEmail(true) }}>
                        <IoSend />
                      </Icon>
                    }
                    </td>
                    <td>
                      {isOpen && isCurrentActive === index
                        ? <Icon onClick={() => { setCurrentActive(null); getCurrentItem(false) }}>
                        <IoChevronUp size={22} />
                       </Icon>
                        : <Icon onClick={() => { setCurrentActive(index); getCurrentItem(true) }}>
                         <IoChevronDown size={22} />
                       </Icon>
                      }
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={7} style={{ borderBottom: 'none' }}>
                    <LogContent isActive={!!(isOpen && isCurrentActive === index)}>
                      <div style={isOpen && isCurrentActive === index ? { display: 'flex', flexDirection: 'column', gap: 20 } : { display: 'none' }}>
                        <div style={{ color: 'white' }}>
                          Info (0):

                        </div>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20, color: theme.COLORS.SECONDARY_700 }}>
                          Warning (3):
                          <div>
                          CPF inválido nos ids: [{format?.warning?.Leads?.cpf?.map((id: string, index: number) => {
                            return (<> [ <a style={{ color: 'white' }} key={index} target={'_blank'} rel={'noreferrer'} href={`https://teddy360.bitrix24.com/crm/lead/details/${id}/`}>{id}</a> ], </>)
                          })} ]
                          </div>
                          <div>
                            CNPJ inválido nos ids: [{format?.warning?.Leads?.cnpj?.map((id: string, index: number) => {
                            return (<> [ <a style={{ color: 'white' }} key={index} target={'_blank'} rel={'noreferrer'} href={`https://teddy360.bitrix24.com/crm/lead/details/${id}/`}>{id}</a> ], </>)
                          })}]
                          </div>
                          <div>
                            Celular inválido nos ids: [{format?.warning?.Leads?.celular?.map((id: string, index: number) => {
                            return (<> [ <a style={{ color: 'white' }} key={index} target={'_blank'} rel={'noreferrer'} href={`https://teddy360.bitrix24.com/crm/lead/details/${id}/`}>{id}</a> ], </>)
                          })}]
                          </div>
                        </div>
                        <div style={{ color: theme.COLORS.ERROR_800 }}>
                          Error (1):
                          <div>
                            Item duplicados no id: [{format?.error?.Leads?.duplicados?.map((id: string, index: number) => {
                            return (<> [ <a style={{ color: 'white' }} key={index} target={'_blank'} rel={'noreferrer'} href={`https://teddy360.bitrix24.com/crm/lead/details/${id}/`}>{id}</a> ], </>)
                          })}]
                          </div>
                        </div>
                      </div>
                    </LogContent>
                    </td>
                    </tr>
                  </>
                )
              }) }

            </tbody>
        </DataTable>
        </Wrap>
       </Container>
      }/>

       </div>
  )
}
export default Logs
