import { Link } from 'react-router-dom'
import styled from 'styled-components'
import theme from '../../../Contants/theme'

export const Container = styled.div`
width: 100%;
height: 100%;
border-radius: 20px;
display: flex;
flex-direction: row;
border-radius: 10px;
`

export const Wrap = styled.div`
 width: 100%;
 height: 100%;
 background-color: ${theme.COLORS.PRIMARY_700};
 padding: 2%;
 border-radius: 10px;
 overflow-x: scroll;
`

export const DataTable = styled.table`
  
  width: 100%;
  thead {
    font-size: 14px;
    border: 1px;
    text-align: center;
  }

  tbody{
    font-size: 12px;
    text-align: center;
  }

  td {
    border-bottom: 1px solid ${theme.COLORS.BORDER_PRIMARY};
    padding-top: 20px;
    padding-bottom: 20px;
    vertical-align: center;
  }

  @media (max-width: 1440px){
    thead {
      font-size: 12px;
      text-align: left;
    }
  }

`

export const Icon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.COLORS.TEXT_SECONDARY};

  &:hover{
    color: #fff;
    background-color: ${theme.COLORS.BORDER_PRIMARY};
    cursor: pointer;
  }
`

export const FilterBar = styled.div`
  width: 100%;
  padding: 0px 0px 30px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  backgroud-color: red;
`

export const SearchBar = styled.input.attrs({ type: 'search' })`
  width: 40%;
  height: 50px;
  background-color: ${theme.COLORS.PRIMARY_700};
  border: 2px solid ${theme.COLORS.BORDER_SECONDARY};
  border-radius: 100px;
  padding-left: 20px;
  color: #fff;
  font-size: 16px;

  @media (max-width: 1440px){
    width: 60%;
    height: 40px;
  }
`

export const TableTitle = styled.h5`
  font-weight: 500;
  font-size: 20px;
`
export const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`

export const AddButton = styled(Link)`
  width: 20%;
  height: 50px;
  border-radius: 100px;
  boder: 1px solid ${theme.COLORS.BORDER_PRIMARY};
  background-color: ${theme.COLORS.SECONDARY_800};
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  @media (max-width: 1440px){
    height: 40px;
    font-size: 16px;
  }
`

export const OptionCard = styled.div`
  padding: 20px 40px;
  border-radius: 20px;
  background-color: ${theme.COLORS.PRIMARY_600};
  position: absolute;
  top: 60px;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
`

export const CardItem = styled.div<{ selected: string }>`
 width: 200px;
 height: 50px;
 display: flex;
 border-radius: 2px;
 justify-content: center;
 align-items: center;
 background-color:${(props) => props.selected === 'edit' ? '#6B5220' : '#493838'};
 border-left: 10px solid ${(props) => props.selected === 'edit' ? '#F6AF26' : theme.COLORS.ERROR_700};
 margin-top: 10px;
 margin-bottom: 10px;
 color: #fff;
 font-size: 18px;
 font-weight: 500;

 &:hover{
  cursor: pointer;
  background-color: ${theme.COLORS.PRIMARY_700};
  border-left-color: ${theme.COLORS.PRIMARY_800};
 }
`

export const CardTitle = styled.h5``
