/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useRef, useState } from 'react'
import { Container, Wrap, Column, Label, Card, CardTitle, CardBody, Selector, Select, Vertical, FilterBar, AddButton, Title, ClearButton, Icon } from './styles'
import dataService from '../../services/data.service'
import Header from '../../components/Header'
import theme from '../../Contants/theme'
import { IoArrowForwardOutline, IoSettingsOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

const Matriz = () => {
  interface IMatriz {
    categoria: string
    grupo: string
    produto: string
    parceiros: string
    sobreOProduto: string
    faturamento: string
    garantia: string
    percentualDeGarantia: string
    tempoMinimoDeFundacaoDaEmpresa: string
    faturamentoMinimoMensal: string
    tipoDeCliente: string
    valorMinimoDeCredito: string
    ValorMaximoDeCredito: string
    formatoDeRecebimento: string
    prazoMaximoDeRecebimento: string
    criteriosParaAnalise: string
    pontoDeAtencao: string
    setoresNaoAtendidosComoSacado: string
    calculoDeComissao: string
    simuladorDeComissao: string
    observacoes: string
    revisao: string
  }

  const navigate = useNavigate()
  const [data, setData] = useState<any[]>([])
  const [initialData, setInitialData] = useState<any[]>([])
  const [breadcrumb, setBreadcrumb] = useState<string[]>([])
  const [breadcrumbActive, setBreadcrumbActive] = useState(true)
  const [selected, setSelected] = useState<string>('')
  const [selectedsList, setSelectedsList] = useState<any[]>([])
  const [isSelected, setIsSelected] = useState(false)
  const [clearSelect, setClearSelect] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState<number>()

  useEffect(() => {
    dataService.getMatriz()
      .then((res) => {
        setInitialData(res)
        setData(res)
      })
      .catch(error => console.log(error))
  }, [])

  useEffect(() => {
    if (data) {
      // eslint-disable-next-line @typescript-eslint/prefer-includes
      const categoriesFilter = data.filter(item => Object.values(item).toString().toLowerCase().indexOf(selected.toLowerCase()) > -1)
      categoriesFilter.length === -1 || selectedsList.length === 0 ? setData(initialData) : setData(categoriesFilter)
    }
  }, [selected])

  useEffect(() => {
  }, [])

  function callDataSelector (section: string) {
    const list = Array.from(new Set(data.map(item => item[section])))

    return list.map((element, index) => {
      return (
        element !== '' && element !== null &&
        <div key={`${element}-${index}`}>
          <Selector
            key={index}
            index={index}
            onClick={(e) => { setData(() => data.filter(item => item[section] === element)) }}>
            {element}
          </Selector>
        </div>
      )
    })
  }

  const callDataSelect = (section: string) => {
    const list = Array.from(new Set(data.map(item => item[section])))
    if (clearSelect) {
      return <option key={''} value={''}></option>
    }
    return list?.map((element: any) => {
      // return <option key={element} value={element}>{element}</option>
      return <option key={element} value={element}>{element}</option>
    }
    )
  }

  const handleSelected = (e: any) => {
    const value = e
    const set = new Set(selectedsList)
    set.has(value) ? set.delete(value) : set.add(value)
    setData(initialData)
    set.size ? setSelected(Array.from(set).at(-1).toString()) : setSelected('')
    setSelectedsList(Array.from(set))
  }

  /* const callDataSelector2 = (section: string) => {
    const newData = data.map(item => item[section])
    const list = Array.from(new Set(newData))
    return list?.map((element: any, index) => {
      return (
        element !== '' && element !== null &&
       <div key={element}>
        { selectedsList.includes(element)
          ? <Selector isSelected={ true } index={index} onClick={(e) => { handleSelected(e); setClearSelect(false) }}>{element}</Selector>
          : <Selector aria-disabled={true} index={index} onClick={(e) => { handleSelected(e); setClearSelect(false); setSelectedIndex(index); console.log(index) }}>{element}</Selector>
        }
       </div>
      )
    })
  }

  const callDataSelect2 = (section: string) => {
    const newData = data.map(item => item[section])
    const list = Array.from(new Set(newData))
    if (clearSelect) {
      return <option key={''} value={''}></option>
    }
    return list?.map((element: any) => {
      // return <option key={element} value={element}>{element}</option>
      return <option key={element} value={element}>{element}</option>
    }
    )
  } */

  return (
        <Header content={
          <Container>
          <FilterBar>
            <Title style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'center' }}>
              Matriz De Qualificação
              <Icon onClick={ () => navigate('/matrizdata')}>
                <IoSettingsOutline />
              </Icon>
            </Title>
            <ClearButton onClick={() => { window.location.reload() }}>Limpar todos os campos</ClearButton>
          </FilterBar>
          { breadcrumb.length > 0
            ? <FilterBar style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
              {
                selectedsList.map((item: string, index) => {
                  return (
                    <div key={index} style={breadcrumbActive ? { color: theme.COLORS.SECONDARY_900, textDecorationColor: theme.COLORS.SECONDARY_900, textDecoration: 'underline' } : { color: '#fff' }} onClick={() => { setSelected(item) }}>
                     &nbsp; {`${item} `} <IoArrowForwardOutline color={'#fff'}/>
                    </div>
                  )
                })
              }
            </FilterBar>
            : null
          }
          <Wrap>
            <Column style={{ width: '70%' }}>
              <Card>
                <CardTitle>Tipo de Cliente</CardTitle>
                <CardBody>
                { callDataSelector('tipoDeCliente') }
                </CardBody>
              </Card>

              <Card>
                <CardTitle>Garantia</CardTitle>
                <CardBody>
                { callDataSelector('garantia') }
                </CardBody>
              </Card>

              <Card>
                <CardTitle>Categoria</CardTitle>
                <CardBody>
                { callDataSelector('categoria') }
                </CardBody>
              </Card>
            </Column>
            <Column style={{ width: '70%' }}>
              <Card style={{ maxHeight: '800px' }}>
                <CardTitle></CardTitle>
                <CardBody style={{ width: '95%', paddingLeft: '2.5%', paddingRight: '2.5%' }}>
                  <Vertical>
                    <Label>Tempo mínimo de fundação da empresa</Label>
                    <Select onChange={(e) => { handleSelected(e.currentTarget.value) }}>
                    { callDataSelect('tempoMinimoDeFundacaoDaEmpresa') }
                    </Select>
                    <Label>Garantia</Label>
                    <Select onChange={(e) => { handleSelected(e.currentTarget.value) }}>
                    { callDataSelect('garantia') }
                    </Select>
                    <Label>Percentual de Garantia</Label>
                    <Select onChange={(e) => { handleSelected(e.currentTarget.value) }}>
                    { callDataSelect('percentualDeGarantia') }
                    </Select>
                    <Label>Valor mínimo de crédito</Label>
                    <Select onChange={(e) => { handleSelected(e.currentTarget.value) }}>
                    { callDataSelect('valorMinimoDeCredito') }
                    </Select>
                    <Label>critérios para análise</Label>
                    <Select onChange={(e) => { handleSelected(e.currentTarget.value) }}>
                    { callDataSelect('criteriosParaAnalise') }
                    </Select>
                  </Vertical>
                </CardBody>
              </Card>

              <Card>
                <CardTitle>Faturamento</CardTitle>
                <CardBody>
                { callDataSelector('faturamentoMinimoMensal') }
                </CardBody>
              </Card>
            </Column>
            <Column style={{ borderRight: 'none' }}>
              <Card>
                <CardTitle>Grupo</CardTitle>
                <CardBody>
                { callDataSelector('grupo') }
                </CardBody>
              </Card>

              <Card>
                <CardTitle>Produto</CardTitle>
                <CardBody>
                { callDataSelector('produto') }
                </CardBody>
              </Card>

              <Card>
                <CardTitle>Parceiros</CardTitle>
                <CardBody>
                { callDataSelector('parceiros') }
                </CardBody>
              </Card>
              </Column>
          </Wrap>
       </Container>

        } />
  )
}
export default Matriz
