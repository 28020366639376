import api from './api'

export class UpdateService {
  async UpdateAll (): Promise<any> {
    return await api.get('/crm/update')
      .then((response) => {
        return response.status
      })
      .catch(err => {
        console.log(err)
      })
  }

  async Bitrix (): Promise<any> {
    return await api.get('/bitrix/update')
      .then((response) => {
        return response.status
      })
      .catch(err => {
        console.log(err)
      })
  }

  async Asaas (): Promise<any> {
    return await api.get('/asaas/update')
      .then((response) => {
        return response.status
      })
      .catch(err => {
        console.log(err)
      })
  }

  async BomControle (): Promise<any> {
    return await api.get('/bomcontrole/update')
      .then((response) => {
        return response.status
      })
      .catch(err => {
        console.log(err)
      })
  }

  async Grafeno (): Promise<any> {
    return await api.get('/grafeno/update')
      .then((response) => {
        return response.status
      })
      .catch(err => {
        console.log(err)
      })
  }
}

export default new UpdateService()
