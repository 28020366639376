export default {
  COLORS: {
    BACKGROUND: '#202020',

    PRIMARY_900: '#151515',
    PRIMARY_800: '#202020',
    PRIMARY_700: '#252525',
    PRIMARY_600: '#303030',
    // PRIMARY_500: '#434343',

    SECONDARY_1000: '#FF4F10',
    SECONDARY_900: '#C94E12',
    SECONDARY_800: '#EB6625',
    SECONDARY_700: '#EF8652',
    SECONDARY_600: '#F3A681',
    SECONDARY_100: '#F8C7D0',

    SECONDARY_TRANSPARENT: '#C94E1255',
    SECONDARY_TRANSPARENT_100: '#C94E1220',
    LINK_PRIMARY: '#FF4F10',
    TEXT_PRIMARY: '#FFFFFF',
    TEXT_SECONDARY: '#B5B5BE',
    TEXT_TERTIARY: '#8A8C93',

    DETAILS_PRIMARY: '#D8D8D8',

    BORDER_PRIMARY: '#434343',
    BORDER_SECONDARY: '#707070',

    CARD_PROPOSAL: '#F5F5F5',
    ICON_PROPOSAL: '#505050',

    BUTTON_PRODUCT_SIMULATION: '#2CA402',

    STATUS_SUCCESS_ICON: '#2CA402',
    STATUS_SUCCESS_BACKGROUND: '#2A6451',

    STATUS_ERROR_ICON: '#EA3A3D',
    STATUS_ERROR_BACKGROUND: '#700101',

    LINK_GENERIC: '#32DE7A',
    BTN_CLICK_FORM_DOCUMENT: '#32DEC6',
    BTN_COPY_LINK: '#DEE515',

    ERROR_900: '#B92727',
    ERROR_800: '#E53E3E',
    ERROR_700: '#EB6B6B',
    ERROR_600: '#F19898',
    ERROR_100: '#F7C5C5',

    WARNING_900: '#C6AB0B',
    WARNING_800: '#F1D112',
    WARNING_700: '#F4DB43',
    WARNING_600: '#F7E473',
    WARNING_100: '#FAEDA3',

    CONCLUDED_900: '#1FBD61',
    CONCLUDED_800: '#32DE7A',
    CONCLUDED_700: '#5DE595',
    CONCLUDED_600: '#89ECB2',
    CONCLUDED_100: '#B4F3CE'
  },

  FONTS: {
    BOLD: 'Montserrat-Bold',
    SEMI_BOLD: 'Montserrat-SemiBold',
    REGULAR: 'Montserrat-Regular',
    LIGHT: 'Montserrat-Light',
    ITALIC: 'Montserrat-Italic'
  }
}
