import { Link } from 'react-router-dom'
import styled from 'styled-components'
import theme from '../../../Contants/theme'
import bg from '../../../assets/images/background/background2.png'
import { Field, Form, ErrorMessage } from 'formik'

export const Body = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${bg});
  background-color: #000;
  background-position: center bottom;
  background-repeat: no-repeat;
  ;
`

export const Container = styled.div`
width: 50%;
height: 60%;
border-radius: 20px;
display: flex;
flex-direction: row;
border-radius: 50px;

@media (min-width: 1200px){
  width: 80%;
  height: 80%;
}

@media (min-width: 2560px){
  width: 50%;
  height: 60%;
}

`

export const Area = styled.div`
width: 60%;
height: 100%;
background-color: ${theme.COLORS.PRIMARY_900};
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border-radius: 20px 0px 0px 20px;
`

export const FormBody = styled(Form)`
width: 40%;
height: 100%;
background-color: ${theme.COLORS.PRIMARY_900};
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border-radius: 0 20px 20px 0px;
gap: 10px;
`

export const Input = styled(Field)`
width: 80%;
height: 50px;
border-radius: 100px;
background-color: ${theme.COLORS.PRIMARY_700};
border: none;
color: #fff;
font-size: 18px;
padding-left: 20px;
`
export const Label = styled.label`
width: 80%;
display: flex;
justify-content: flex-start;
font-size: 16px;
font-weight: 700;
color: #fff;
margin-bottom: 8px;
margin-top: 8px;
`
export const Button = styled.button`
width: 80%;
height: 50px;
border-radius: 100px;
background-color: ${theme.COLORS.SECONDARY_800};
border: none;
margin-bottom: 0px;
margin-top: 10px;
color: white;
font-size: 20px;
font-weight: 700;
display: flex;
justify-content: center;
align-items: center;
text-decoration: none;

&:hover {
  background-color: ${theme.COLORS.SECONDARY_900};
  cursor: pointer;
}

`

export const Title = styled.h2`
`

export const Logo = styled.div`
`

export const ForgetPass = styled(Link)`
color: white;
width: 80%;
font-size: 16px;
font-weight: 500;
display: flex;
justify-content: flex-end;
text-decoration: none;
margin-top: 10px;
`

export const ErrorMessageStyled = styled.div`
color: red;
font-size: 16px;
font-weight: 500;
width: 100%;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
text-decoration: none;
margin-top: 20px;
`
