import styled from 'styled-components'
import theme from '../../Contants/theme'

export const Container = styled.div`
width: 100%;
height: 100%;
border-radius: 20px;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
border-radius: 20px;
gap: 20px;
`

export const Message = styled.h1`
font-size: 30px;
color: #fff;
`

export const SubMessage = styled.h3`
font-size: 18px;
color: ${theme.COLORS.TEXT_SECONDARY};
`

export const UpdateButton = styled.button`
width: 20%;
height: 50px;
padding: 5px;
border-radius: 100PX;
background-color: ${theme.COLORS.SECONDARY_800};
border: none;
color: #fff;
font-size: 20px;
font-weight: 700;

&:hover {
  background-color: ${theme.COLORS.SECONDARY_900};
  cursor: pointer;
}
`
export const Footer = styled.div`
  width: 100%;
  position: fixed;
  bottom: 20px;
  text-align: center;
  font-size: 14px;
`
