/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { useContext, useEffect, useState } from 'react'
import Header from '../../components/Header'
import theme from '../../Contants/theme'
import { Container, Footer, Message, SubMessage, UpdateButton } from './styles'
import LoadingSpinner from '../../components/LoadingSpinner'
import DataService from '../../services/data.service'
import moment from 'moment'
import 'moment/locale/pt-br'
import logo from '../../assets/images/logo-teddy/logo2x.png'
import { IoCheckmarkCircle, IoCloseCircle } from 'react-icons/io5'
import { io } from 'socket.io-client'
import updateService from '../../services/update.service'
import { API_URL } from '../../services/api_url'

const Main = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState('Todos os dados estão atualizados.')
  const [subMessage, setSubMessage] = useState('Última atualização dos dados:')
  const [isAdmin, setIsAdmin] = useState(true)
  const [lastUpdate, setLastUpdate] = useState<string>('')
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const [status, setStatus] = useState<any>()

  useEffect(() => {
    const getLastUpdate = async () => {
      return await DataService.getLastUpdateDate()
        .then((res) => {
          const dateFormat = (moment(res.createdAT).format('DD/MM/YYYY hh:mm'))
          setLastUpdate(dateFormat)
          setSubMessage(`Última atualização dos dados: ${dateFormat}`)
        }).catch((error) => console.log(error))
    }
    getLastUpdate()
    checkStatus()
  }, [])

  const socket = io(API_URL)
  useEffect(() => {
    socket.on('status', (result) => {
      setStatus(result)
    })
    return () => {
      socket.off('connect')
      socket.off('status')
    }
  })

  useEffect(() => {
    checkStatus()
  }, [status])

  function checkStatus () {
    if (status) {
      if (status.Bitrix && status.Asaas && status.Vadu && status.grafeno && status.BomControle && status.General === 'stopped') {
        console.log('entrei aqui no stopped')
        setIsLoading(false)
        setMessage('Dados Atualizados com sucesso')
        setSubMessage(`Ultima atualização: ${lastUpdate}`)
      }

      if (status.Bitrix || status.Asaas || status.Vadu || status.Grafeno || status.BomControle || status.General === 'updating') {
        for (const key in status) {
          if (status[key] === 'updating' && key !== 'General') {
            setIsLoading(true)
            setMessage(`Atualizando dados do Job ${key}`)
            setSubMessage('Aguarde isso pode levar alguns minutos.')
          }
        }
      }

      if ((status.Bitrix || status.Asaas || status.Vadu || status.Grafeno || status.BomControle === 'error') && (status.General === 'updating')) {
        for (const key in status) {
          if (status[key] === 'error') {
            setIsLoading(true)
            setSubMessage(`erro ao atualizar ${key}`)
          }
        }
      }

      if ((status.Bitrix && status.Asaas && status.Vadu && status.grafeno && status.BomControle === 'success') && status.General === 'stopped') {
        setIsLoading(false)
        setMessage('Dados Atualizados com sucesso')
        setSubMessage(`Ultima atualização: ${lastUpdate}`)
      }

      if ((status.Bitrix || status.Asaas || status.Vadu || status.Grafeno || status.BomControle === 'error') && (status.General === 'stopped')) {
        for (const key in status) {
          if (status[key] === 'error') {
            setIsLoading(false)
            setMessage('Erro ao atualizar dados')
            setSubMessage(`Erro no Job ${key}`)
          }
        }
      }
    }
  }

  const handleUpdatestatus = async () => {
    checkStatus()
    setIsLoading(true)
    await updateService.UpdateAll()
      .then((result) => {
        if (result === 200) {
          checkStatus()
          // nothing here
        } else {
          setMessage('Erro ao atualizar dados')
          setSubMessage('...')
          setIsLoading(false)
          console.log(result)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
  return (
    <Header content={
      <Container>
        {
        isAdmin
          ? <>
          {message !== 'Erro ao atualizar dados' ? <IoCheckmarkCircle size={150} color={theme.COLORS.PRIMARY_600}/> : <IoCloseCircle size={150} color={theme.COLORS.PRIMARY_600}/>}
        <div style={{ textAlign: 'center' }}>
        <Message>{message}</Message>
        <SubMessage>{subMessage}</SubMessage>
        </div>
        { isLoading
          ? <LoadingSpinner/>
          : status !== undefined
            ? <UpdateButton onClick={async () => { await handleUpdatestatus() }}>
              Atualizar dados
            </UpdateButton>
            : null
        }
        </>
          : <>
        <img src={logo}/>
        <div style={{ textAlign: 'center' }}>
        <Message>Bem Vindo!</Message>
        <SubMessage>Solução Teddy Backoffice V 0.1 </SubMessage>
        </div>
        </>
       }
       <Footer>
        Atualizado em 26 de dezembro de 2022. <br/>
        Solução Teddy Backoffice V 0.1.
       </Footer>
      </Container>

    }/>
  )
}
export default Main
