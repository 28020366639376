/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Container, HeaderFixed, Icon, ProfileContainer, Item, Menu, Horizontal, SubMenu, User, Content } from './styles'
import logo from '../../assets/images/logo-teddy/logoHeader.png'
import { IoIosClose, IoIosMenu } from 'react-icons/io'
import { useEffect, useState } from 'react'
import { IoApps, IoBarChart, IoCalendar, IoChevronDownOutline, IoChevronUpOutline, IoExit, IoHome, IoMail, IoPencil, IoPerson, IoPersonCircle, IoReload, IoReorderTwoSharp, IoServer, IoSettingsOutline } from 'react-icons/io5'
import { Link, redirect } from 'react-router-dom'
import { useAuth } from '../../contexts/auth'

const Header = (props: any) => {
  useEffect(() => {
    const storagedUser = (JSON.parse(localStorage.getItem('user') || '{}'))
    setUser(storagedUser.name)
  }, [])

  const context = useAuth()

  const [user, setUser] = useState<string | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [subItemDeth, setSubItemDeth] = useState(0)
  const [subItemDethMesa, setSubItemDethMesa] = useState(0)

  const menuAuthorization = () => {
    switch (context.user.permissions) {
      case 'Admin':
        return AdminHeader()
      case 'User':
        return CrmHeader()
      case 'Matriz':
        return MatrizHeader()
    }
  }

  const AdminHeader = () => {
    return (
      <>
        <Item>
        <Link to="/users">
           <Horizontal>
           <IoPerson />
               Perfil / Acessos
           </Horizontal>
           </Link>
        </Item>
        <MatrizHeader/>
        <TaskHeader/>
        <CrmHeader/>
      </>
    )
  }

  const MatrizHeader = () => {
    return (
      <>
      <Item onClick={() => {
        subItemDethMesa === 0 ? setSubItemDethMesa(1) : setSubItemDethMesa(0)
      }}>
        <Horizontal>
          <IoReorderTwoSharp />
            Mesa
        </Horizontal>
           { subItemDethMesa === 0 ? <IoChevronDownOutline /> : <IoChevronUpOutline /> }
      </Item>
         { subItemDethMesa === 1
           ? <SubMenu>
        <Item>
        <Link to="/matriz">
         <Horizontal>
         <IoApps />
           Matriz de Qualificação
         </Horizontal>
         </Link>
        </Item>
        </SubMenu>
           : null
        }
      </>
    )
  }

  const TaskHeader = () => {
    return (
      <Item>
      <Link to="/taskmanager">
      <Horizontal>
      <IoCalendar />
          Agendamento
      </Horizontal>
      </Link>
      </Item>
    )
  }

  const CrmHeader = () => {
    return (
      <>
      <Item onClick={() => { subItemDeth === 0 ? setSubItemDeth(1) : setSubItemDeth(0) }}>
      <Horizontal>
      <IoBarChart />
        CRM
      </Horizontal>
      { subItemDeth === 0
        ? <IoChevronDownOutline />
        : <IoChevronUpOutline />
      }
    </Item>

  {subItemDeth === 1 || subItemDeth === 2
    ? <SubMenu>
   <Item>
   <Link to="/emails">
    <Horizontal>
    <IoMail/>
      Email Notificação
    </Horizontal>
    </Link>
   </Item>
    <Item onClick={() => { subItemDeth === 1 ? setSubItemDeth(2) : setSubItemDeth(1) }}>
        <Horizontal>
        <IoServer/>
        JOB Datamart
        </Horizontal>
     { subItemDeth === 1
       ? <IoChevronDownOutline />
       : <IoChevronUpOutline />
      }
    </Item>
  {subItemDeth === 2
    ? <SubMenu>
    <Item>
     <Link to="/updatedata/Bitrix">
       <Horizontal>
         Bitrix
         <IoReload size={18}/>
       </Horizontal>
     </Link>
    </Item>
    <Item>
     <Link to="/updatedata/Asaas">
       <Horizontal>
         Asaas
         <IoReload size={18}/>
       </Horizontal>
     </Link>
     </Item>
     <Item>
     <Link to="/updatedata/Grafeno">
       <Horizontal>
         Grafeno
         <IoReload size={18}/>
       </Horizontal>
     </Link>
    </Item>
     <Item>
     <Link to="/updatedata/BomControle">
       <Horizontal>
         Bom Controle
         <IoReload size={18}/>
       </Horizontal>
     </Link>
    </Item>
    <Item>
      <Link to="/logs">
      <Horizontal>
       Log de Ocorrência
      </Horizontal>
      </Link>
      </Item>
    </SubMenu>
    : null }
  </SubMenu>
    : null
  }
  </>
    )
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
      <HeaderFixed>
        <div style={{ flex: 1 }}>
         {!isOpen
           ? <Icon style={{ marginLeft: 50 }} onClick={() => setIsOpen(true)}>
            <IoIosMenu/>
          </Icon>
           : <Icon style={{ marginLeft: 50 }} onClick={() => setIsOpen(false)}>
            <IoIosClose />
        </Icon>}
        </div>
        <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', alignSelf: 'center' }}>
          <img src={logo}/>
         </div>
         <div style={{ flex: 1 }} />
      </HeaderFixed>

       <Container isOpen={isOpen}>
         <ProfileContainer>
           <IoPersonCircle size={100} color={'#fff'}/>
           <User>
             <>
             {user}
             <IoSettingsOutline size={16} />
             </>
           </User>
         </ProfileContainer>
         <Menu>
         <Item>
         <Link to="/">
            <Horizontal>
            <IoHome />
                Inicío
            </Horizontal>
            </Link>
        </Item>

          {menuAuthorization()}

          <Item onClick={() => { context.Logout(); redirect('/login') }}>
            <Horizontal>
            <IoExit />
              Sair
            </Horizontal>
          </Item>
         </Menu>
       </Container>

       <Content isOpen={isOpen}>
        {props.content}
       </Content>
    </div>
  )
}
export default Header

/*
 <Item>
   Assertiva
   <IoReload size={18}/>
 </Item>
 <Item>
   Bom Controle
   <IoReload size={18}/>
 </Item>
*/
