/* eslint-disable no-sequences */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Area, Body, Button, Container, UserInput, PassInput, Title, Label, Logo, ForgetPass, ErrorMessage, Permissions, Item, Horizontal, FormBox, Header } from './styles'
import logo from '../../../assets/images/logo-teddy/logoHeader.png'
import { useNavigate, Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import LoadingSpinner from '../../../components/LoadingSpinner'
import authService from '../../../services/auth.service'
import { IoArrowBackCircleOutline } from 'react-icons/io5'
import { Formik, Form, Field } from 'formik'
import dataService from '../../../services/data.service'

const Register = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [groups, setGroups] = useState<any[]>([])
  const [groupsSelected, setGroupSelected] = useState<any[]>([])
  const [permissions, setPermissions] = useState('Bitrix')
  const [BitrixSelect, setBitrixSelect] = useState(false)
  const [AssertivaSelect, setAssertivaSelect] = useState(false)
  const [BomControleSelect, setBomControleSelect] = useState(false)
  const [AsaasSelect, setAsaasSelect] = useState(false)
  const [authorization, setAuthorization] = useState('')
  const [adminActive, setAdminActive] = useState(false)
  const [userActive, setUserActive] = useState(false)
  const [passwordCheck, setPasswordCheck] = useState(true)

  const navigate = useNavigate()

  useEffect(() => {
    dataService.getAllGroups()
      .then((res) => {
        setGroups(res)
      })
      .catch((error) => console.log(error))
  }, [])

  const validatePass = (e: any) => {
    if (e !== password) {
      setError('As senhas digitadas não são iguais')
    } else {
      setError('')
    }
  }

  const handleSelect = (e: any) => {
    if (permissions !== 'Bitrix') {
      setPermissions('')
      setBitrixSelect(false)
    } else {
      console.log(permissions)
      setPermissions('Bitrix')
      setBitrixSelect(true)
    }
  }

  const validate = () => {
    if (password && passwordConfirm) {
      return true
    }
    setError('Preencha todos os campos')
    return false
  }

  const handleRegister = (email: string, password: string, name: string, permissions: string, authorization: string, groups?: []) => {
    if (validate()) {
      setIsLoading(true)
      authService.register(email, password, name, permissions, authorization, groups)
        .then((result) => {
          console.log(result.response)
          if (result.status === 201) {
            navigate('/users')
          }
          // if (result.data.message === 'Password requirements not met.') {
          //   setIsLoading(false)
          //   setError('A senha precisa ter no mínimo 8 caracteres, 1 Maiuscula, 1 caractere especial.')
          // }
          if (result.response.status === 400) {
            alert('Erro ao criar a conta, tente novamente')
            setIsLoading(false)
          }
        }, error => {
          console.log(error)
          setIsLoading(false)
          setError('erro ao cadastrar usuário.')
        })
    } else {
      setError('Preencha todos os campos')
    }
  }

  const handleAdmin = (e: any) => {
    switch (e) {
      case 'Administrador':
        setAuthorization('Admin')
        setUserActive(false)
        setAdminActive(true)
        setPermissions('Bitrix | Matriz de Qualificação')
        break
      case 'Usuário':
        setAuthorization('User')
        setAdminActive(false)
        setUserActive(true)
        setPermissions('Bitrix')
        break
    }
  }

  function verifyPassword (password: string) {
    const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
    return regex.test(password)
  }

  function handlePassword (password: string) {
    if (verifyPassword(password)) {
      setPasswordCheck(true)
      setPassword(password)
    } else {
      setPasswordCheck(false)
    }
  }

  return (
    <Body>

     <Container>
      <Area>
      <Header>
        <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Link style={{ textDecoration: 'none', color: '#fff', marginLeft: 20 }} to={'/users'}>
              <IoArrowBackCircleOutline size={30} />
            </Link>
        </div>
        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Logo>
            <img src={logo}/>
          </Logo>
        </div>
        <div style={{ flex: 1 }}>
        </div>
      </Header>
      </Area>
        <FormBox>
        <Formik
          initialValues = {{
            name: '',
            email: '',
            password: '',
            authorization: ''
          }}
          onSubmit = {(values) => {
            values.authorization = authorization
            handleRegister(values.email, password, values.name, permissions, values.authorization)
          }}>
        <Form style={{ width: '100%', marginLeft: 50, marginRight: 50 }}>
          <Title>Novo Usuário</Title>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginRight: 60 }}>
            <Label>Nome completo</Label>
            <UserInput name='name' />

            <Label>E-mail</Label>
            <UserInput name='email'/>

            <Label>Senha</Label>
            <PassInput
              type='password'
              id='password'
              name='password'
              value={password}
              onChange={ (e: any) => { handlePassword(e.target.value) }}
            />

            <Label>Confirme sua senha</Label>
            <PassInput
              type='password'
              id='passwordConfirm'
              name={'passwordConfirm'}
              value={passwordConfirm}
              onChange={ (e: any) => { validatePass(e.target.value), setPasswordConfirm(e.target.value) }}
            />
          </div>
          <div style={{ width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <Label>Permissões</Label>
            <UserInput as={'select'} name={'authorization'} onChange={(e: any) => { setAuthorization(e.target.value) }}>
              <option></option>
              <option value={'Admin'}>Administrador</option>
              <option value={'User'}>Usuário</option>
              <option value={'Matriz'}>Matriz</option>
            </UserInput>

            { authorization === 'Admin'
              ? <>
                <Label>Descrição das Permissões</Label><Permissions>
                Administrador:
                  Bitrix | Matriz de Qualificação
              </Permissions>
              </>
              : authorization === 'User'
                ? <><Label>Descrição das Permissões</Label>
            <Permissions>
                Matriz de Qualificação | Atualizações
            </Permissions></>
                : authorization === 'Matriz'
                  ? <><Label>Descrição das Permissões</Label>
                  <Permissions>
                      Matriz de Qualificação
                  </Permissions></>
                  : null
            }

          <Label>Grupo</Label>
            <UserInput as={'select'} name={'group'} onChange={(e: any) => { setGroupSelected(prev => [...prev, e.currentTarget]); console.log(e.currentTarget.value) } }>
              <option value={1}></option>
              {
                groups.map((item) => {
                  return <option key={item.id} value={item.id}>{item.name}</option>
                })
              }
            </UserInput>

            { passwordCheck
              ? <div>
                <h4>Verifique sua senha:</h4>
                <ol>
                <li>Deve conter pelo menos 8 caracteres</li>
                <li>Deve conter pelo menos uma letra maiúscula</li>
                <li>Deve conter pelo menos um número</li>
                <li>Deve conter pelo menos um caractere especial (por exemplo, @, $, !, %, *, ?, &)</li>
                </ol>
              </div>
              : null
            }

          </div>
        </div>
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
          <div style={{ marginTop: 30, marginBottom: 10 }}>
          {error !== '' && <ErrorMessage> {error} </ErrorMessage>}
          </div>
          <div>
            {!isLoading
              ? <Button>
              Cadastrar
             </Button>
              : <div style={{ width: '65%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <LoadingSpinner />
              </div>
            }
            </div>
          </div>
        </Form>
        </Formik>
        </FormBox>
     </Container>
     </Body>
  )
}
export default Register
