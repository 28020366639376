import { useEffect, useState } from 'react'
import Header from '../../../components/Header'
import dataService from '../../../services/data.service'
import { Container, DataTable, Wrap, Icon, FilterBar, SearchBar, TableTitle, Horizontal, AddButton, OptionCard, CardTitle, CardItem } from './styles'
import { MdMoreVert } from 'react-icons/md'
import { IoSettingsOutline, IoFilter, IoClose } from 'react-icons/io5'
import theme from '../../../Contants/theme'

const MatrizData = () => {
  const [data, setData] = useState([])
  const [initialData, setInitialData] = useState([])
  const [selected, setSelected] = useState(false)
  const [search, setSearch] = useState('')
  const [isActive, setIsActive] = useState(false)
  const [listItemIndex, setListItemIndex] = useState<number>()

  useEffect(() => {
    dataService.getMatriz()
      .then((res) => {
        setInitialData(res)
        setData(res)
      })
      .catch(error => console.log(error))
  }, [])

  useEffect(() => {
    if (data) {
      // eslint-disable-next-line @typescript-eslint/prefer-includes
      const Filter = data.filter(item => Object.values(item).toString().toLowerCase().indexOf(search.toLowerCase()) > -1)
      Filter.length === 0 || search === '' || Filter.length === -1 ? setData(initialData) : setData(Filter)
    }
  }, [search])

  const handleEdit = (id: number) => {
    console.log(id)
  }

  const handleRemove = (produto: string, categoria: string, id: number) => {
    let txt
    if (confirm(`Tem certeza que deseja excluir o produto: ${produto}, da categoria: ${categoria}?`)) {
      txt = 'You pressed OK!'
    } else {
      txt = 'You pressed Cancel!'
    }
  }

  return (
      <div>
      <Header content={
        <Container>
        <Wrap>
          <FilterBar>
            <TableTitle>Matriz de Qualificação</TableTitle>
            <Horizontal style={{ width: '50%', justifyContent: 'flex-end', gap: 50, alignItems: 'center' }}>
              <SearchBar
              placeholder={'Pesquisar'}
              onChange={(e: any) => setSearch(e.target.value)}
              />
            </Horizontal>
            <AddButton to={'/addmatriz'}>
             Adicionar produto
            </AddButton>
          </FilterBar>
        <DataTable>
            <thead>
              <tr>
                <th>Categoria</th>
                <th>Grupo</th>
                <th>Produto</th>
                <th>Parceiros</th>
                <th>Sobre o produto</th>
                <th>Garantia</th>
                <th>Faturamento</th>
                <th>Faturamento minimo mensal</th>
                <th>Percentual da garantia</th>
                <th>Valor minimo de crédito</th>
                <th>Valor maximo de crédito</th>
                <th>Tipo de cliente</th>
                <th>Tempo minimo de fundação</th>
                <th>Formato de recebimento</th>
                <th>Prazo maximo de recebimento</th>
                <th>Setores não atendidos como sacado</th>
                <th>observações</th>
                <th>Simulador de comissões</th>
                <th>Revisão</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item: any, index) => {
                return (
                  <tr key={item.id}>
                    <td>{item.categoria}</td>
                    <td>{item.grupo}</td>
                    <td>{item.produto}</td>
                    <td>{item.parceiros}</td>
                    <td>{item.sobreOProduto}</td>
                    <td>{item.garantia}</td>
                    <td>{item.faturamento}</td>
                    <td>{item.faturamentoMinimoMensal}</td>
                    <td>{item.percentualDeGarantia}</td>
                    <td>{item.valorMinimoDeCredito}</td>
                    <td>{item.ValorMaximoDeCredito}</td>
                    <td>{item.tipoDeCliente}</td>
                    <td>{item.tempoMinimoDeFundacaoDaEmpresa}</td>
                    <td>{item.formatoDeRecebimento}</td>
                    <td>{item.prazoMaximoDeRecebimento}</td>
                    <td>{item.setoresNaoAtendidosComoSacado}</td>
                    <td>{item.observacoes}</td>
                    <td>{item.simuladorDeComissao}</td>
                    <td>{item.revisao}</td>
                    <td style={{ position: 'relative', verticalAlign: 'center', display: 'flex', justifyContent: 'flex-end', gap: 10, color: `${theme.COLORS.SECONDARY_800}` }}>
                      {isActive && index === listItemIndex
                        ? <>
                        <Icon onClick={() => setIsActive(false)}>
                          <IoClose size={22} />
                        </Icon>
                        <OptionCard>
                            {
                              <>
                                <CardItem key={index} selected={'edit'} onClick={(e) => { handleEdit(item.id) }}>
                                  Editar
                                </CardItem>
                                <CardItem key={index} selected={'remove'} onClick={(e) => { handleRemove(item.produto, item.categoria, item.id) }}>
                                  Excluir
                                </CardItem>
                              </>
                                }
                        </OptionCard>
                        </>
                        : <Icon onClick={() => { setIsActive(true); setListItemIndex(index) }}>
                      <MdMoreVert size={20} />
                    </Icon>
                    }
                  </td>
                  </tr>
                )
              }) }

            </tbody>
        </DataTable>
        </Wrap>
       </Container>
      }/>

       </div>
  )
}
export default MatrizData
