import styled from 'styled-components'
import theme from '../../Contants/theme'

export const Container = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
margin-bottom: 20px;
`

export const Wrap = styled.div`
 width: 100%;
 height: 100%;
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 background-color: ${theme.COLORS.PRIMARY_700};
 padding: 1% 0% 1% 0%;
 border-radius: 20px;
`

export const Column = styled.div<{ isTitle?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 1%;
  padding-right: 1%;
  gap: 20px;
  flex-direction: ${props => props.isTitle ? 'row' : 'column'};
  align-items: ${props => props.isTitle ? 'flex-start' : ''};
  justify-content: ${props => props.isTitle ? 'flex-start' : 'space-between'};
  border-right: ${props => props.isTitle ? 'none' : '1px solid #414141'};
`
export const Title = styled.h3``

export const Card = styled.div`
width: 98%;
max-height: 180px;
overflow-y: scroll;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
background-color: ${theme.COLORS.PRIMARY_600};
border-radius: 10px;
padding: 1%;
padding-bottom: 20px;

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: ${theme.COLORS.PRIMARY_700};
}

::-webkit-scrollbar-thumb {
  background: ${theme.COLORS.PRIMARY_800};
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 1440px){
  max-height: 200px;
}
@media (min-width: 1800px){
  max-height: 400px;
}

`
export const CardTitle = styled.h3`
width: 95%;
padding-left: 5%;
font-size: 14px;
`
export const CardBody = styled.div`
width: 100%;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
gap: 10px;
flex-wrap: wrap;
`
export const Select = styled.select`
  width: 100%;
  height: 50px;
  background-color: ${theme.COLORS.PRIMARY_800};
  border: 2px solid ${theme.COLORS.BORDER_SECONDARY};
  border-radius: 100px;
  padding-left: 2.5%;
  padding-right: 2.5%;
  color: #fff;
  font-size: 14px;

  @media (max-width: 1440px) {
    height: 40px;
    font-size: 12px;
  }

`

export const Vertical = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
`

export const Detail = styled.div`
  width: 60%;
  height: 1px;
  background-color: ${theme.COLORS.SECONDARY_800};
`
export const NameInput = styled.input.attrs({ type: 'search' })`
  width: 90%;
  height: 50px;
  background-color: ${theme.COLORS.PRIMARY_700};
  border: 2px solid ${theme.COLORS.BORDER_SECONDARY};
  border-radius: 100px;
  padding-left: 20px;
  color: #fff;
  font-size: 16px;
`

export const DescriptionInput = styled.textarea`
  width: 90%;
  height: 200px;
  background-color: ${theme.COLORS.PRIMARY_700};
  border: 2px solid ${theme.COLORS.BORDER_SECONDARY};
  border-radius: 10px;
  padding-left: 20px;
  color: #fff;
  font-size: 16px;
`
export const Label = styled.label`
  font-size: 14px;
  font-weight: 600;

  @media (max-width: 1440px) {
    font-size: 12px;
  }
`
export const List = styled.ul`
  width: 100%;
`
export const Item = styled.li`
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 10px;
  font-size: 18px;
  &:hover{
    background-color: ${theme.COLORS.PRIMARY_800};
    cursor: pointer;
  }
`
export const Check = styled.input.attrs({ type: 'checkbox' })`
`

export const Icon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.COLORS.TEXT_SECONDARY};

  &:hover{
    color: #fff;
    background-color: ${theme.COLORS.BORDER_PRIMARY};
    cursor: pointer;
  }
`

export const FilterBar = styled.div`
  width: 100%;
  padding: 0px 0px 30px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  backgroud-color: red;
`

export const SearchBar = styled.input.attrs({ type: 'search' })`
  width: 90%;
  height: 50px;
  background-color: ${theme.COLORS.PRIMARY_800};
  border: 2px solid ${theme.COLORS.BORDER_SECONDARY};
  border-radius: 100px;
  padding-left: 20px;
  color: #fff;
  font-size: 16px;
`
export const AddButton = styled.button`
  width: 40%;
  height: 50px;
  border-radius: 100px;
  boder: 1px solid ${theme.COLORS.BORDER_PRIMARY};
  background-color: ${theme.COLORS.SECONDARY_800};
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
`
export const ClearButton = styled.button`
  width:15%;
  height: 50px;
  border-radius: 100px;
  background-color: ${theme.COLORS.SECONDARY_800};
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  border: none;

  &:hover{
    cursor: pointer;
  }

  @media (max-width: 1440px) {
    width: 16%;
    height: 40px;
    font-size: 14px;
  }
  
`

export const Permission = styled.div<{ isSelected: boolean }>`
  width: 45%;
  height: 50px;
  border-radius: 10px;
  background-color: ${(props) => props.isSelected ? theme.COLORS.CONCLUDED_900 : theme.COLORS.PRIMARY_800};
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:hover{
    background-color: ${(props) => props.isSelected ? theme.COLORS.CONCLUDED_800 : theme.COLORS.PRIMARY_600};
    cursor: pointer;
  }
`

export const Selector = styled.div<{ isSelected?: boolean, index?: number }>`
  width: 150px;
  height: 60px;
  border-radius: 10px;
  background-color: ${(props) => props.isSelected ? theme.COLORS.SECONDARY_800 : theme.COLORS.PRIMARY_800};
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  word-break: break-word;
  padding: 10px;
  @media (max-width: 1440px) {
    width: 150px;
    padding: 5px;
    height: 40px;
    border-radius: 7px;
    font-size: 12px;
  }
`
