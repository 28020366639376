import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useAuth } from '../contexts/auth'
import Login from '../pages/auth/login/main'
import Register from '../pages/auth/register/main'
import RequestChangePassword from '../pages/auth/ResetPassword/main'
import DataUpdate from '../pages/DataUpdate'
import Emails from '../pages/Email'
import AddGroup from '../pages/Email/components/AddGroup'
import Logs from '../pages/Logs'
import Main from '../pages/Main'
import Matriz from '../pages/Matriz'
import AddMatrizData from '../pages/Matriz/AddData'
import EditMatriz from '../pages/Matriz/Edit'
import MatrizData from '../pages/Matriz/MatrizData'
import TaskManager from '../pages/TaskManager'
import Users from '../pages/Users'
import { ProtectedRoute } from './ProtectedRoute'

const DefaultRoutes = () => {
  return (
  <BrowserRouter>
      <Routes>
        <Route element={<Login />} path="/login" />
        <Route element={<RequestChangePassword />} path="/requestchangepassword" />
        <Route element={<ProtectedRoute><Main /></ProtectedRoute>} path="/" />
        <Route element={<ProtectedRoute><Users/></ProtectedRoute>} path="/users" />
        <Route element={<ProtectedRoute><Logs/></ProtectedRoute>} path="/logs" />
        <Route element={<ProtectedRoute><Register/></ProtectedRoute>} path="/register" />
        <Route element={<ProtectedRoute><DataUpdate /></ProtectedRoute>} path="/updatedata/:id" />
        <Route element={<ProtectedRoute><Emails /></ProtectedRoute>} path="/emails" />
        <Route element={<ProtectedRoute><AddGroup /></ProtectedRoute>} path="/emails/new" />
        <Route element={<ProtectedRoute><Matriz /></ProtectedRoute>} path="/matriz" />
        <Route element={<ProtectedRoute><MatrizData /></ProtectedRoute>} path="/matrizdata" />
        <Route element={<ProtectedRoute><AddMatrizData /></ProtectedRoute>} path="/addmatriz" />
        <Route element={<ProtectedRoute><EditMatriz/></ProtectedRoute>} path="/editmatriz/:id" />
        <Route element={<ProtectedRoute><TaskManager /></ProtectedRoute>} path="/taskmanager" />
      </Routes>
    </BrowserRouter>
  )
}

export default DefaultRoutes
