import { useEffect, useState } from 'react'
import { Container, Wrap, Column, Icon, FilterBar, SearchBar, AddButton, NameInput, DescriptionInput, Label, Item, List, Check, Title, WrapTitle, Detail, Permission } from './styles'
import { IoClose } from 'react-icons/io5'
import theme from '../../../../Contants/theme'
import dataService from '../../../../services/data.service'
import Header from '../../../../components/Header'
import { useNavigate } from 'react-router-dom'

const AddGroup = () => {
  const navigate = useNavigate()
  const permissionsList = [
    { title: 'Bitrix' },
    { title: 'ASAAS' },
    { title: 'Assertiva' },
    { title: 'Bom Controle' },
    { title: 'Vadu' }
  ]

  const handleClick = (currentPermissions: string, selected: any) => {
    const newArray = currentPermissions.split(' | ')

    if (newArray.includes(selected)) {
      setSelected(true)
    } else {
      setSelected(false)
    }
  }

  const handleClick2 = (currentPermissions: string, selected: any) => {
    const newArray = currentPermissions.split(' | ')

    if (newArray.includes(selected)) {
      setSelected(true)
    } else {
      setSelected(false)
      switch (selected) {
        case 'Bitrix' :
          newArray.push('Bitrix')
          break
        case 'Assertiva' :
          newArray.push('Assertiva')
          break
        case 'ASAAS' :
          newArray.push('ASAAS')
          break
        case 'Bom Controle' :
          newArray.push('Bom Controle')
          break
        case 'Vadu' :
          newArray.push('Vadu')
          break
      }
    };
  }

  useEffect(() => {
    dataService.getAllUsers()
      .then((result) => {
        setInitialData(result)
        setData(result)
      }).catch(error => { return error })
  }, [])

  const [data, setData] = useState([])
  const [initialData, setInitialData] = useState([])
  const [selectedData, setSelectedData] = useState<any>([])
  const [selected, setSelected] = useState(false)
  const [search, setSearch] = useState('')
  const [currentPermissions, setCurrentPermissions] = useState('')
  const [isActive, setIsActive] = useState(false)
  const [error, setError] = useState('')
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [permissions, setPermissions] = useState<string[]>([])
  const [users, setUsers] = useState<any>([])

  const handleAdd = () => {
    const permissionsToAdd = permissions.toString().replaceAll(/,/g, ' | ')

    dataService.newGroup(name, description, permissionsToAdd, users)
      .then((res) => {
        console.log(res)
        if (res === 'error: O grupo já existe!') {
          alert('Já existe um grupo com esse nome, por favor, altere os nome')
        } else {
          console.log('caiu no else')
          // navigate('/emails')
        }
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    if (data) {
      // eslint-disable-next-line @typescript-eslint/prefer-includes
      const categoriesFilter = data.filter(item => Object.values(item).toString().toLowerCase().indexOf(search.toLowerCase()) > -1)
      categoriesFilter.length === 0 || search === '' || categoriesFilter.length === -1 ? setData(initialData) : setData(categoriesFilter)
    }
  }, [search])

  const handleSelectedData = (item: any) => {
    setSelectedData((prev: any) => [...prev, item])
    setUsers((prev: any) => [...prev, { id: item.id }])
  }

  const handleRemoveItem = (item: any) => {
    setSelectedData((prev: any) => prev.filter((prevItem: any) => prevItem !== item))
    setUsers((prev: any) => prev.filter((prevItem: any) => prevItem !== item))
  }

  const handlePermissions = (e: any) => {
    const permission = e.currentTarget.innerText
    permissions.includes(permission)
      ? setPermissions((prev: any) => prev.filter((prevItem: any) => prevItem !== permission))
      : setPermissions((prev: any) => [...prev, permission])
  }

  return (
        <Header content={
          <Container>
          <WrapTitle>
            <Column isTitle={true}>
              <Title>
                Detalhes do Grupo
              </Title>
              <Detail />
            </Column>
            <Column isTitle={true}>
              <Title>
                Selecionar Participantes
              </Title>
              <Detail />
            </Column>
            <Column isTitle={true}>
              <Title>
                Selecionados
              </Title>
              <Detail />
            </Column>
          </WrapTitle>
          <Wrap>
            <Column>
              <Label>Nome do Grupo</Label>
              <NameInput onChange={(e) => setName(e.target.value)}/>

              <Label>Descrição do Grupo</Label>
              <DescriptionInput onChange={(e) => setDescription(e.target.value)}/>

              <Label>Permissões de notificação</Label>
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', rowGap: 5, columnGap: 20 }}>{
                permissionsList.map((item, index) => {
                  let isSelected = false
                  permissions.includes(item.title) ? isSelected = true : isSelected = false
                  return (
                    <Permission key={index} isSelected={isSelected} onClick={(e) => { handlePermissions(e) }}>
                      {item.title}
                    </Permission>
                  )
                })
              }
              </div>
            </Column>
            <Column>
             <SearchBar placeholder={'Pesquisar usuário'}
             onChange={(e) => { setSearch(e.currentTarget.value) }}
             />
             <List>
                {
                  data.map((item: any, index) => {
                    return (
                      <Item key={index} onClick={() => { handleSelectedData(item) }}>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <div>
                           {item.name}
                          </div>
                            {item.email}
                        </div>
                        </div>
                      </Item>
                    )
                  })
                }
             </List>
            </Column>
            <Column style={{ borderRight: 'none' }}>
             <List>
                {
                  selectedData
                    ? selectedData.map((item: any, index: any) => {
                      return (
                      <Item key={index}>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <div>
                           {item.name}
                          </div>
                            {item.email}
                        </div>
                        </div>
                        <Icon onClick={() => { handleRemoveItem(item) }}>
                          <IoClose size={20}/>
                        </Icon>
                      </Item>
                      )
                    })
                    : null
                }
             </List>
            </Column>
          </Wrap>
          <WrapTitle style={{ justifyContent: 'center', alignItems: 'center', paddingBottom: 40 }}>
            <AddButton onClick={() => { handleAdd() }}>
              Salvar Grupo
            </AddButton>
          </WrapTitle>
       </Container>

        } />
  )
}
export default AddGroup
