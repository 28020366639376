import { Field } from 'formik'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import theme from '../../../Contants/theme'

export const Container = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items:center;
`

export const Wrap = styled.div`
 width: 60%;
 height: 100%;
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 background-color: ${theme.COLORS.PRIMARY_700};
 padding: 0% 2%;
 border-radius: 20px;
 overflow-x: hidden;
 margin-bottom: 20px;
`

export const Column = styled.div<{ isTitle?: boolean }>`
  width: 100%;
  display: flex;
  padding: 0px 1%;
  flex-direction: ${props => props.isTitle ? 'row' : 'column'};
  align-items: flex-start;
  gap: ${props => props.isTitle ? '20px' : '0px'};
`
export const Title = styled.h4`
`
export const TextField = styled(Field)`
  width: 95%;
  height: 40px;
  background-color: ${theme.COLORS.PRIMARY_700};
  border: 2px solid ${theme.COLORS.BORDER_SECONDARY};
  border-radius: 100px;
  padding: 0% 2%;
  color: #fff;
  font-size: 16px;
`

export const TextArea = styled(Field)`
  width: 100%;
  height: 200px;
  background-color: ${theme.COLORS.PRIMARY_700};
  border: 2px solid ${theme.COLORS.BORDER_SECONDARY};
  border-radius: 10px;
  padding: 2% 2%;
  color: #fff;
  font-size: 16px;
`
export const Label = styled.label`
  font-size: 16px;
  font-weight: 600;
  margin-top: 50px;
  margin-bottom: 20px;
`

export const FormGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`
export const Group = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const FormButton = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 100px;
  border: none;
  background-color: ${theme.COLORS.SECONDARY_800};
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 20px;
`
export const AddButton = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: none;
  background-color: ${theme.COLORS.PRIMARY_800};
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  top: 20px;
  left: 10px;s
  &:hover{
    background-color: ${theme.COLORS.PRIMARY_600};
  }
`
