import styled from 'styled-components'
import theme from '../../Contants/theme'

export const Container = styled.div`
width: 100%;
height: 100%;
border-radius: 20px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: 20px;
gap: 30px;
`

export const Message = styled.h1`
font-size: 30px;
color: #fff;
`

export const SubMessage = styled.h3`
font-size: 20p;
color: ${theme.COLORS.TEXT_SECONDARY};
`
