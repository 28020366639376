import { BlockList } from 'net'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import theme from '../../Contants/theme'

export const Container = styled.div`
width: 100%;
height: 100%;
border-radius: 20px;
display: flex;
flex-direction: row;
border-radius: 10px;
`

export const Wrap = styled.div`
 width: 100%;
 height: 100%;
 background-color: ${theme.COLORS.PRIMARY_700};
 padding: 2%;
 border-radius: 20px;
`

export const DataTable = styled.table`
  
  width: 100%;
  thead {
    font-size: 18px;
    border: 1px;
    text-align: left;
  }

  tbody{
    font-size: 16px;
    text-align: left;
  }

  td {
    border-bottom: 1px solid ${theme.COLORS.BORDER_PRIMARY};
    vertical-align: bottom;
    padding-bottom: 10px;
  }

`

export const Icon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.COLORS.TEXT_SECONDARY};

  &:hover{
    color: #fff;
    background-color: ${theme.COLORS.BORDER_PRIMARY};
    cursor: pointer;
  }
`

export const FilterBar = styled.div`
  width: 100%;
  padding: 0px 0px 30px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SearchBar = styled.input.attrs({ type: 'search' })`
  width: 30%;
  height: 40px;
  background-color: ${theme.COLORS.PRIMARY_700};
  border: 2px solid ${theme.COLORS.BORDER_SECONDARY};
  border-radius: 100px;
  padding-left: 20px;
  color: #fff;
  font-size: 16px;

  @media (max-width: 1440px){
    width: 80%;
    height: 40px;
  }
`

export const TableTitle = styled.h5`
  font-weight: 500;
  font-size: 20px;
`
export const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`

export const AddButton = styled(Link)`
  width: 20%;
  height: 50px;
  border-radius: 100px;
  boder: 1px solid ${theme.COLORS.BORDER_PRIMARY};
  background-color: ${theme.COLORS.SECONDARY_800};
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
`

export const LogContent = styled.div<{ isActive: boolean }>`
  width: 96%;
  padding: ${(props) => props.isActive ? '2% 2%' : '0'};
  margin-bottom: 2%;
  height: ${(props) => props.isActive ? '100%' : '0px'};
  border-radius: 20px;
  background-color: ${theme.COLORS.PRIMARY_900};
  align-self: center;
  transition: 200ms linear;

  @media (min-width: 1200px){
    width: 96%;
    word-break: break-all;
    padding: ${(props) => props.isActive ? '2% 2%' : '0'};
  }
`

export const DateInput = styled.input.attrs({ type: 'date' })`
  width: 30%;
  height: 50px;
  background-color: ${theme.COLORS.PRIMARY_700};
  border: 2px solid ${theme.COLORS.BORDER_SECONDARY};
  border-radius: 100px;
  padding-left: 50px;
  padding-right: 50px;
  color: #fff;
  font-size: 16px;

  @media (min-width: 1200px){
    width: 40%;
    padding-left: 20px;
    padding-right: 20px;
    height: 40px;
  }
`

export const EmailCard = styled.div`
  width: 500px;
  padding: 5% 5%;
  background-color: ${theme.COLORS.PRIMARY_600};
  border-radius: 20px;
  position: absolute;
  top: -18px;
  right: 50px;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
`

export const EmailCardItem = styled.div`
  padding: 5% 5%;
  width: 90%;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &:hover{
    cursor: pointer;
    background-color: ${theme.COLORS.PRIMARY_800};
  }
`

export const EmailCardTopBar = styled.div`
  width: 90%;
  padding: 2% 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid ${theme.COLORS.BORDER_PRIMARY};
`

export const EmailCardActionBar = styled.div`
  width: 100%;
  padding: 5% 0% 5% 0%;
  border-top: 2px solid ${theme.COLORS.BORDER_PRIMARY};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const EmailCardActionSendButton = styled.div`
  width: 40%;
  height: 40px;
  background-color: ${theme.COLORS.SECONDARY_900};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;

  &:hover{
    cursor: pointer;
    background-color: ${theme.COLORS.SECONDARY_800};
  }
`
