/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect, useState } from 'react'
import Header from '../../components/Header'
import dataService from '../../services/data.service'
import { Container, DataTable, Wrap, Icon, FilterBar, SearchBar, TableTitle, Horizontal, AddButton, OptionCard, CardTitle, CardItem, CardItemOptions } from './styles'
import { MdMoreVert } from 'react-icons/md'
import { IoSettingsOutline, IoFilter, IoClose } from 'react-icons/io5'
import theme from '../../Contants/theme'

const Users = () => {
  const permissionsList = [
    { title: 'Bitrix' },
    { title: 'Asaas' },
    { title: 'Assertiva' },
    { title: 'Bom Controle' },
    { title: 'Vadu' }
  ]

  const [data, setData] = useState([])
  const [initialData, setInitialData] = useState([])
  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState('')
  const [activeIndex, setActiveIndex] = useState<number>()
  const [listItemIndex, setListItemIndex] = useState<number>()
  const [currentPermissions, setCurrentPermissions] = useState('')
  const [permissions, setPermissions] = useState<string[]>([])
  const [isActive, setIsActive] = useState(false)
  const [isOptionsActive, setIsOptionsActive] = useState(false)

  useEffect(() => {
    dataService.getAllUsers()
      .then((result) => {
        setData(result)
        setInitialData(result)
      }).catch((e) => {
        console.log(e)
      })
  }, [])

  const handlePermissions = (e: any) => {
    const permission = e.currentTarget.innerText
    permissions.includes(permission)
      ? setPermissions((prev: any) => prev.filter((prevItem: any) => prevItem !== permission))
      : setPermissions((prev: any) => [...prev, permission])
  }

  const getCurrentPermissions = (permissions: String) => {
    console.log(permissions)
    setPermissions([])
    const format = permissions.split(' | ')
    console.log(format)
    setPermissions((prev: any) => [...prev, ...format])
  }

  const updatePermissions = async (id: number, permissions: string[]) => {
    const permissionsToAdd = permissions.toString().replaceAll(/,/g, ' | ')
    console.log(permissionsToAdd)
    await dataService.updateUserPermissions(id, permissionsToAdd)
      .then((res) => {
        res === 200
          ? window.location.reload()
          : alert('Erro ao atualizar, por favor tente novamente')
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (data) {
      // eslint-disable-next-line @typescript-eslint/prefer-includes
      const Filter = data.filter(item => Object.values(item).toString().toLowerCase().indexOf(search.toLowerCase()) > -1)
      Filter.length === 0 || search === '' || Filter.length === -1 ? setData(initialData) : setData(Filter)
    }
  }, [search])

  return (
      <div>
      <Header content={
        <Container>
        <Wrap>
          <FilterBar>
            <TableTitle>Usuários</TableTitle>
            <Horizontal style={{ width: '50%', justifyContent: 'flex-end', gap: 50, alignItems: 'center' }}>
              <Horizontal>
                Ordenar por
                <IoFilter size={20}/>
              </Horizontal>
              <SearchBar
              placeholder={'Pesquisar'}
              onChange={(e: any) => setSearch(e.target.value)}
              />
            </Horizontal>
            <AddButton to={'/register'}>
             Novo Usuário
            </AddButton>
          </FilterBar>
        <DataTable>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>Email</th>
                <th>Permissões</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item: any, index) => {
                return (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td style={{ position: 'relative', verticalAlign: 'center', display: 'flex', justifyContent: 'center', gap: 10, color: `${theme.COLORS.SECONDARY_800}` }}>
                      {item.permissions}
                      {isActive && index === activeIndex
                        ? <>
                        <Icon onClick={() => { setIsActive(false); updatePermissions(item.id, permissions) }}>
                          <IoClose size={22} />
                        </Icon>
                          <OptionCard>
                            {
                              permissionsList.map((permission, index) => {
                                let isSelected = false
                                const currentPermissions = item.permissions.split(' | ')
                                console.log(permissions)
                                permissions.includes(permission.title) ? isSelected = true : isSelected = false
                                console.log(isSelected)
                                return (
                                  <CardItem key={index} selected={isSelected} onClick={(e) => { handlePermissions(e) }}>
                                    {permission.title}
                                  </CardItem>
                                )
                              })
                            }
                          </OptionCard>
                        </>
                        : <Icon onClick={() => { getCurrentPermissions(item.permissions); setActiveIndex(index); setIsActive(true) }}>
                      <IoSettingsOutline size={20} />
                    </Icon>
                    }
                  </td>
                  <td>
                    <div style={{ position: 'relative', verticalAlign: 'center', display: 'flex', justifyContent: 'flex-end', gap: 10, color: `${theme.COLORS.SECONDARY_800}` }}>
                    {isOptionsActive && index === listItemIndex
                      ? <>
                        <Icon onClick={() => setIsOptionsActive(false)}>
                          <IoClose size={22} />
                        </Icon>
                        <OptionCard>
                            {
                            <>
                            <CardItemOptions key={index} selected={true} status={'edit'} onClick={(e) => {} }>
                                Editar
                              </CardItemOptions>
                              <CardItemOptions key={index} selected={false} status={'delete'} onClick={(e) => {} }>
                                  Excluir
                                </CardItemOptions>
                            </>}
                        </OptionCard>
                        </>
                      : <Icon onClick={() => { setIsOptionsActive(true); setListItemIndex(index) }}>
                       <MdMoreVert size={25} />
                        </Icon>
                    }
                    </div>
                    </td>
                  </tr>
                )
              }) }

            </tbody>
        </DataTable>
        </Wrap>
       </Container>
      }/>

       </div>
  )
}
export default Users
