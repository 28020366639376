import api from './api'
class AuthService {
  async login (email: string, password: string): Promise<any> {
    return await api.post('/auth/login', { email, password })
      .then((response) => {
        return response.status
      })
      .catch(err => {
        console.log(err)
      })
  }

  // logout() {}

  async register (email: string, password: string, name: string, permissions: string, authorization: string, groups?: any[]): Promise<any> {
    return await api
      .post('/users', { email, password, name, permissions, authorization })
      .then((response) => {
        console.log(response)
        return response
      })
      .catch(err => {
        return err
      })
  }

  async restPassword (user: string): Promise<any> {
    return await api
      .post('/users/resetpassword', { user })
      .then((response) => {
        return response
      })
      .catch(err => {
        console.log(err)
      })
  }

  async confirmRestPassword (user: string, verificationCode: string, newPassword: string): Promise<any> {
    return await api
      .post('/users/confirmresetpassword', { user, verificationCode, newPassword })
      .then((response) => {
        return response
      })
      .catch(err => {
        console.log(err)
      })
  }
}

export default new AuthService()
