import api from './api'

class DataService {
  async getAllUsers (): Promise<any> {
    return await api
      .get('/users')
      .then((response) => {
        return response.data
      })
      .catch(err => {
        console.log(err)
      })
  }

  async updateUserPermissions (id: number, permissions: string): Promise<any> {
    return await api.put(`/users/permissions/${id}`, { permissions })
      .then((response) => {
        return response.status
      })
      .catch(err => {
        console.log(err)
      })
  }

  async getAllGroups (): Promise<any> {
    return await api
      .get('/emails/groups')
      .then((response) => {
        return response.data
      })
      .catch(err => {
        console.log(err)
      })
  }

  async updateAllBitrixData (): Promise<any> {
    return await api.get('/crm/updatebitrixdata')
      .then((response) => {
        return response.status
      })
      .catch(err => {
        console.log(err)
      })
  }

  async updateAll (): Promise<any> {
    return await api.get('/crm/update')
      .then((response) => {
        return response.status
      })
      .catch(err => {
        console.log(err)
      })
  }

  async checkUpdateStatus (): Promise<any> {
    return await api.get('/crm/status')
  }

  async newGroup (name: string, description: string, permissions: string, users: number[]): Promise<any> {
    return await api.post('/emails/group', { name, description, permissions, users })
      .then((response) => {
        return response.data
      })
      .catch(err => {
        console.log(err)
      })
  }

  async updateGroupPermissions (id: number, permissions: string): Promise<any> {
    return await api.put(`/emails/group/${id}`, { permissions })
      .then((response) => {
        return response.status
      })
      .catch(err => {
        console.log(err)
      })
  }

  async getLastUpdateDate (): Promise<any> {
    return await api.get('/logs/getlastupdate')
      .then((response) => {
        return response.data
      })
      .catch(err => {
        console.log(err)
      })
  }

  async getAllLogs (): Promise<any> {
    return await api.get('/logs')
      .then((response) => {
        return response.data
      })
      .catch(error => {
        return error
      })
  }

  async getMatriz (): Promise<any> {
    return await api.get('/matriz')
      .then((response) => {
        return response.data
      })
      .catch(error => {
        return error
      })
  }

  async getMatrizByOne (id: number): Promise<any> {
    return await api.get(`/matriz/${id}`)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        return error
      })
  }

  async postMatriz (data: {}): Promise<any> {
    return await api.post('/matriz', data)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        return error
      })
  }

  async sendIndividualEmail (data: any): Promise<any> {
    return await api
      .post('/emails/sendindividual', data)
      .then((response) => {
        return response.data
      })
      .catch(error => {
        return error
      })
  }

  async getTask (): Promise<any> {
    return await api.get('/taskmanager/task')
      .then((response) => {
        return response.data
      })
      .catch(error => {
        return error
      })
  }

  async updateTask (id: number, name: string, time: string, period: string): Promise<any> {
    return await api.put(`/taskmanager/task${id}`, { name, time, period })
      .catch((error) => console.log('erro update task', error))
  }
}

export default new DataService()
