import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../contexts/auth'

export const ProtectedRoute = ({ children }: any) => {
  const { signed } = useAuth()
  if (!signed) {
    // user is not authenticated
    return <Navigate to="/login" />
  }
  return children
}
