/* eslint-disable no-async-promise-executor */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { createContext, useContext, useEffect, useState } from 'react'
import api from '../services/api'

interface AuthContextData {
  signed: boolean
  user: {
    email: string
    name: string
    permissions: string
  }
  Login: (email: string, password: string) => Promise<any>
  Logout: () => void
}
const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<any>(JSON.parse(localStorage.getItem('user')!))

  useEffect(() => {
    async function loadStorageData () {
      const storagedUser = await localStorage.getItem('user')
      const storagedToken = await localStorage.getItem('token')
      if (storagedToken && storagedUser) {
        setUser(JSON.parse(storagedUser))
        api.defaults.headers.Authorization = `Bearer ${storagedToken}`
      }
    }
    loadStorageData()
  }, [])

  async function Login (email: string, password: string) {
    return await new Promise(async (resolve, reject) => {
      await api.post('/auth/login', {
        email,
        password
      }).then((response) => {
        resolve(
          'sucess'
        )
        console.log(JSON.stringify(response.data.user))
        api.defaults.headers.Authorization = `Bearer ${response.data.token}`
        localStorage.setItem('user', JSON.stringify(response.data.user))
        localStorage.setItem('token', response.data.token)
        setUser(response.data.user)
      }).catch((error) => {
        reject(
          console.log(error)
        )
      })
    })
  }

  function Logout () {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    setUser(null)
  }

  return (
    <AuthContext.Provider value={{ signed: Boolean(user), user, Login, Logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth () {
  const context = useContext(AuthContext)
  return context
}
