/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios from 'axios'
import { API_URL } from './api_url'
// const API_URL = API_URL
// const API_URL = process.env.REACT_APP_API_URL
// const token = `${localStorage.getItem('token')}` !== undefined ? `Bearer ${localStorage.getItem('token')}` : ''
const api = axios.create({
  baseURL: API_URL,
  timeout: 600000
})

export default api

/*
headers: {
    Authorization: token,
    'Content-Type': 'application/json'
  }
*/
