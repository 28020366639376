import { Field } from 'formik'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import theme from '../../Contants/theme'

export const Container = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
`

export const Wrap = styled.div`
 width: 100%;
 height: 100%;
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 background-color: ${theme.COLORS.PRIMARY_700};
 padding: 1% 0% 1% 0%;
 border-radius: 0px;
 overflow-x: hidden;
`

export const Column = styled.div<{ isTitle?: boolean }>`
  width: 100%;
  display: flex;
  padding: 0px 1%;
  flex-direction: ${props => props.isTitle ? 'row' : 'column'};
  align-items: ${props => props.isTitle ? 'center' : 'flex-start'};
  gap: ${props => props.isTitle ? '20px' : '0px'};
  border-right: ${props => props.isTitle ? 'none' : '1px solid #414141'};
  overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 0px;
    }
`
export const Title = styled.h4`
`
export const WrapTitle = styled.div`
width: 100%;
height: 100%;
display: flex;
padding-top: 30px;
flex-direction: row;
justify-content: center;
align-items: center;
background-color: ${theme.COLORS.PRIMARY_700};
`
export const Detail = styled.div`
  width: 50%;
  height: 1px;
  background-color: ${theme.COLORS.SECONDARY_800};
`
export const TextField = styled(Field)`
  width: 95%;
  height: 40px;
  background-color: ${theme.COLORS.PRIMARY_700};
  border: 2px solid ${theme.COLORS.BORDER_SECONDARY};
  border-radius: 100px;
  padding: 0% 2%;
  color: #fff;
  font-size: 16px;
`
export const InputNumber = styled.input.attrs({ type: 'number' })`
  width: 95%;
  height: 40px;
  background-color: ${theme.COLORS.PRIMARY_700};
  border: 2px solid ${theme.COLORS.BORDER_SECONDARY};
  border-radius: 100px;
  padding: 0% 2%;
  color: #fff;
  font-size: 16px;
`

export const DateInput = styled.input.attrs({ type: 'date' })`
  width: 100%;
  height: 50px;
  background-color: ${theme.COLORS.PRIMARY_700};
  border: 2px solid ${theme.COLORS.BORDER_SECONDARY};
  border-radius: 100px;
  padding-left: 50px;
  padding-right: 50px;
  color: #fff;
  font-size: 16px;

  @media (min-width: 1200px){
    width: 40%;
    padding-left: 20px;
    padding-right: 20px;
    height: 40px;
  }
`

export const Card = styled.div`
  width: 95%;
  height: 100%;
  padding: 1% 2.5%;
  background-color: ${theme.COLORS.PRIMARY_900};
  border-radius: 20px;
`

export const CardHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${theme.COLORS.BORDER_PRIMARY};
`
export const CardEdit = styled.div`
  width: 90%;
  height: 100;
  padding: 1% 2.5%;
  background-color: ${theme.COLORS.PRIMARY_900};
  border-radius: 20px;
  gap: 20px;
`

export const CardHour = styled.div`
width: 98%;
max-height: 180px;
overflow-y: scroll;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
background-color: ${theme.COLORS.PRIMARY_600};
border-radius: 10px;
padding: 1%;
padding-bottom: 20px;

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: ${theme.COLORS.PRIMARY_700};
}

::-webkit-scrollbar-thumb {
  background: ${theme.COLORS.PRIMARY_800};
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 1440px){
  max-height: 200px;
}
@media (min-width: 1800px){
  max-height: 400px;
}

`
export const CardBody = styled.div`
width: 100%;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
gap: 10px;
flex-wrap: wrap;
`

export const DescriptionInput = styled.textarea`
  width: 90%;
  height: 200px;
  background-color: ${theme.COLORS.PRIMARY_700};
  border: 2px solid ${theme.COLORS.BORDER_SECONDARY};
  border-radius: 10px;
  padding-left: 20px;
  color: #fff;
  font-size: 16px;
`
export const Label = styled.label`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
`
export const List = styled.ul`
  padding: 0;
  list-style-position: initial;
  list-style-type: none;
`
export const Item = styled.li`
  width: 100%;
  left:0;
  gap: 20px;
  padding: 0 2%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  font-size: 18px;
  &:hover{
    background-color: ${theme.COLORS.PRIMARY_800};
    cursor: pointer;
  }
`
export const Check = styled.input.attrs({ type: 'checkbox' })`
`

export const Icon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.COLORS.TEXT_SECONDARY};

  &:hover{
    color: #fff;
    background-color: ${theme.COLORS.BORDER_PRIMARY};
    cursor: pointer;
  }
`

export const FilterBar = styled.div`
  width: 100%;
  padding: 0px 0px 30px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  backgroud-color: red;
`

export const SearchBar = styled.input.attrs({ type: 'search' })`
  width: 90%;
  height: 50px;
  background-color: ${theme.COLORS.PRIMARY_800};
  border: 2px solid ${theme.COLORS.BORDER_SECONDARY};
  border-radius: 100px;
  padding-left: 20px;
  color: #fff;
  font-size: 16px;
`
export const Button = styled.button<{ cancel?: boolean }>`
  width: 40%;
  height: 50px;
  border-radius: 100px;
  border: ${(props) => props.cancel ? `1px solid ${theme.COLORS.ERROR_800}` : 'none'};
  background-color: ${(props) => props.cancel ? theme.COLORS.PRIMARY_800 : theme.COLORS.SECONDARY_800};
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;

  &:hover{
    background-color: ${(props) => props.cancel ? theme.COLORS.ERROR_800 : theme.COLORS.SECONDARY_900};
    cursor: pointer;
  }
`

export const SaveButton = styled.button.attrs({ type: 'submit' })`
  width: 40%;
  height: 50px;
  border-radius: 100px;
  border: none;
  background-color: ${theme.COLORS.SECONDARY_800};
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;

  &:hover{
    background-color: ${theme.COLORS.SECONDARY_900};
    cursor: pointer;
  }
`

export const HourCard = styled.div<{ isSelected: boolean }>`
  width: 200px;
  height: 50px;
  border-radius: 5px;
  background-color: ${(props) => props.isSelected ? theme.COLORS.CONCLUDED_900 : theme.COLORS.PRIMARY_800};
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:hover{
    background-color: ${(props) => props.isSelected ? theme.COLORS.CONCLUDED_800 : theme.COLORS.PRIMARY_600};
    cursor: pointer;
  }
`

export const Selector = styled.div<{ isSelected?: boolean }>`
  width: 150px;
  height: 60px;
  border-radius: 10px;
  background-color: ${(props) => props.isSelected ? theme.COLORS.SECONDARY_800 : theme.COLORS.PRIMARY_800};
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  word-break: break-word;
  padding: 10px;
  @media (max-width: 1440px) {
    width: 150px;
    padding: 5px;
    height: 40px;
    border-radius: 7px;
    font-size: 12px;
  }

  &:hover{
    background-color: ${(props) => props.isSelected ? theme.COLORS.SECONDARY_900 : theme.COLORS.PRIMARY_900};
    cursor: pointer;
  }
`
