import styled from 'styled-components'
import theme from '../../Contants/theme'

export const HeaderFixed = styled.header`
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 0px;
    position: fixed;
    top: 0;
    height: 80px;
    width: 100%;
    background-color: ${theme.COLORS.PRIMARY_900};
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    z-index: 1;

    @media (max-width: 599px) {
      padding: 0px 0px 0px 20px;
     }
`

export const Icon = styled.div`
  width: 40px;
  height: 40px;
  color: #fff;
  font-size: 40px;

  &:hover{
   cursor: pointer;
  }
`

export const Container = styled.div< { isOpen: boolean }>`
  width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 80px;
  background-color: ${theme.COLORS.PRIMARY_800};
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  transition: all 0.35s ease-out;
  position: fixed;
  left: ${(props) => props.isOpen ? '0px' : '-400px'};

  @media (max-width: 599px) {
    width:50%;
    left: ${(props) => props.isOpen ? '0' : '-100%'};
    z-index: 1;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
    ::-webkit-scrollbar {
      width: 10px;
    }
   }
  @media (min-width: 600px) { }
  @media (min-width: 900px) { }

  @media (max-width: 1440px){
    overflow-y: scroll;
    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${theme.COLORS.BACKGROUND}; 
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${theme.COLORS.BACKGROUND}; 
    }

    padding-top: 80px;
    padding-bottom: 120px;
    width: 300px;
    left: ${(props) => props.isOpen ? '0' : '-300px'};
    
   }
  @media (min-width: 1800px) { 
    left: ${(props) => props.isOpen ? '0' : '-500px'};
  }
  
`

export const ProfileContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
`

export const Menu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 1440px){
    padding-bottom: 120px;
   }
`

export const Item = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  padding-top: 20px;
  padding-bottom: 20px;

  &:hover {
    color: ${theme.COLORS.SECONDARY_800};
    cursor: pointer;
  }

  a{
    text-decoration: none;
    color: #fff;
  } 

  a:hover {
    color: ${theme.COLORS.SECONDARY_800};
    cursor: pointer;
  }

  @media (max-width: 1440px){
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 14px;
  }
  @media (min-width: 1800px){
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 18px;
  }


`

export const SubMenu = styled.div`
 width: 80%;
 margin-left: 30px;

 @media (max-width: 1440px){
    width: 85%;
    margin-left: 30px;
  }
`

export const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`

export const User = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 20px;
  font-weight: 600;
  gap: 20px;
  align-items: center;
`

export const Content = styled.div<{ isOpen: boolean }>`
  width: ${(props) => props.isOpen ? '80%' : '90%'};
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 200px;
  left: ${(props) => props.isOpen ? '450px' : '5%'};
  transition: all 0.35s ease-out;

  @media (max-width: 599px) {
    width:100%;
    left: ${(props) => props.isOpen ? '100%' : '0%'};
   }

  @media (max-width: 1440px){
    width: ${(props) => props.isOpen ? '74%' : '95%'};
    top: 120px;
    left: ${(props) => props.isOpen ? '320px' : '2.5%'};
  }

  @media (min-width: 1800px){
    width: ${(props) => props.isOpen ? '80%' : '90%'};
    top: 120px;
    left: ${(props) => props.isOpen ? '450px' : '5%'};
  }

`
