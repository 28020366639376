/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useEffect, useState } from 'react'
import { Container, Wrap, Column, AddButton, TextArea, Label, FormButton, FormGroup, Group, TextField } from './styles'
import { IoAdd, IoLogoWindows, IoRemove } from 'react-icons/io5'
import theme from '../../../Contants/theme'
import dataService from '../../../services/data.service'
import Header from '../../../components/Header'
import { Formik, Form, Field } from 'formik'
import api from '../../../services/api'
import { Message } from '../../DataUpdate/styles'
import { useParams, useNavigate } from 'react-router-dom'

interface Idata {
  categoria: string
  grupo: string
  produto: string
  parceiros: string
  garantia: string
  tipoDeCliente: string
  prazoMaximoDeRecebimento: string
  setoresNaoAtendidosComoSacado: string
  calculoDeComissao: string
  simuladorDeComissao: string
  observacoes: string
  revisao: string
  sobreOProduto: string
  tempoMinimoDeFundacaoDaEmpresa: string
  formatoDeRecebimento: string
  pontoDeAtencao: string
  criteriosParaAnalise: string
  faturamento: string
  percentualDeGarantia: string
  faturamentoMinimoMensal: string
  valorMinimoDeCredito: string
  valorMaximoDeCredito: string
}

const EditMatriz = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState<Idata | undefined>(undefined)
  const [allData, setAllData] = useState<string[]>([])
  const [addNew, setAddNew] = useState<string[]>([])

  useEffect(() => {
    function allData () {
      dataService.getMatriz()
        .then((res) => {
          setAllData(res)
          console.log(res)
        })
        .catch(error => console.log(error))
    }
    allData()
    id
      ? dataService.getMatrizByOne(parseInt(id))
        .then((res) => {
          setData(res)
        })
        .catch(error => console.log(error))
      : navigate('/addmatriz')
  }, [])

  console.log(data)

  const initial = () => {
    if (data !== undefined) {
      return {
        categoria: data.categoria,
        grupo: data.grupo,
        produto: data.produto,
        parceiros: data.parceiros,
        garantia: data.garantia,
        tipoDeCliente: data.tipoDeCliente,
        prazoMaximoDeRecebimento: data.prazoMaximoDeRecebimento,
        setoresNaoAtendidosComoSacado: data.setoresNaoAtendidosComoSacado,
        calculoDeComissao: data.calculoDeComissao,
        simuladorDeComissao: data.simuladorDeComissao,
        observacoes: data.observacoes,
        revisao: data.revisao,
        sobreOProduto: data.sobreOProduto,
        tempoMinimoDeFundacaoDaEmpresa: data.tempoMinimoDeFundacaoDaEmpresa,
        formatoDeRecebimento: data.formatoDeRecebimento,
        pontoDeAtencao: data.pontoDeAtencao,
        criteriosParaAnalise: data.criteriosParaAnalise,
        faturamento: data.faturamento,
        percentualDeGarantia: data.percentualDeGarantia,
        faturamentoMinimoMensal: data.faturamentoMinimoMensal,
        valorMinimoDeCredito: data.valorMinimoDeCredito,
        valorMaximoDeCredito: data.valorMaximoDeCredito
      }
    }
  }

  const callDataField = (section: string) => {
    const newData = allData.map(item => item === section)
    const list = Array.from(new Set(newData))
    return list?.map((element: any) =>
      element !== '' && ' ' &&
          <option key={element} value={element} onClick={(e: any) => { handleField('TextField', e.currentTarget.innerText) }}>{element}</option>
    )
  }

  const handleField = (Field: string, value: any) => {
    let newValue = ''
    return (
      <TextField
        onChange={(e: any) => { newValue = e.target.value }}
      />
    )
  }

  const handleAddNew = (Field: any) => {
    if (addNew.includes(Field)) {
      setAddNew(addNew.filter(item => item !== Field))
    } else {
      setAddNew([...addNew, Field])
    }
    console.log(addNew)
  }

  return (
        <Header content={
          <Container>
          <Wrap>
            <Column>

            <Formik
              initialValues = {initial}
              onSubmit = {(values) => {
                dataService.postMatriz(values)
                  .then((res) => alert('Produto adicionado com sucesso!'))
                  .catch((error) => alert(`Erro ao adicionar produto! \n error: ${error}`))
              }}>
            <Form style={{ width: '95%' }}>
              <FormGroup>
                <Group>
                  <Label>Produto</Label>
                  <TextArea as='textarea' name='produto' style={{ marginTop: 20 }} placeholder={'Nome do produto'} />
                </Group>
              </FormGroup>

              <FormGroup>
                <Group>
                  <Label>Sobre o produto</Label>
                  <TextArea as='textarea' name='sobreOProduto' style={{ marginTop: 20 }} placeholder={'Descrição do produto'} />
                </Group>
              </FormGroup>

                <FormGroup>
                  <Group>
                    <Label>Categoria</Label>
                    <TextField as='select' name={'categoria'}>
                    { callDataField('categoria') }
                    </TextField>
                  </Group>
                  { !addNew.includes('categoria')
                    ? <AddButton onClick={ () => handleAddNew('categoria') }>
                    <IoAdd />
                  </AddButton>
                    : <AddButton onClick={ () => handleAddNew('categoria') }>
                      <IoRemove/>
                    </AddButton>}
                </FormGroup>
                {addNew.includes('categoria')
                  ? <TextField
                    type='text'
                    name='categoria'
                    style={{ marginTop: 20 }}
                    placeholder={'Adicionar nova Categoria'}/>
                  : null }

                <FormGroup>
                  <Group>
                    <Label>Tipo De Cliente</Label>
                    <TextField as='select' name={'tipoDeCliente'}>
                      { callDataField('tipoDeCliente') }
                    </TextField>
                  </Group>
                  {!addNew.includes('tipoDeCliente')
                    ? <AddButton onClick={ () => handleAddNew('tipoDeCliente') }>
                    <IoAdd />
                  </AddButton>
                    : <AddButton onClick={ () => handleAddNew('tipoDeCliente') }>
                      <IoRemove/>
                    </AddButton>}
                </FormGroup>
                {addNew.includes('tipoDeCliente') ? <TextField name={'tipoDeCliente'} style={{ marginTop: 20 }} placeholder={'Adicionar novo Tipo De Cliente'}/> : null }

                <FormGroup>
                  <Group>
                    <Label>Tempo Mínimo De Fundação Da Empresa</Label>
                    <TextField as='select' name={'tempoMinimoDeFundacaoDaEmpresa'}>
                      { callDataField('tempoMinimoDeFundacaoDaEmpresa') }
                    </TextField>
                  </Group>
                  {!addNew.includes('tempoMinimoDeFundacaoDaEmpresa')
                    ? <AddButton onClick={ () => handleAddNew('tempoMinimoDeFundacaoDaEmpresa') }>
                    <IoAdd />
                  </AddButton>
                    : <AddButton onClick={ () => handleAddNew('tempoMinimoDeFundacaoDaEmpresa') }>
                      <IoRemove/>
                    </AddButton>}
                </FormGroup>
                {addNew.includes('tempoMinimoDeFundacaoDaEmpresa') ? <TextField name={'tempoMinimoDeFundacaoDaEmpresa'} style={{ marginTop: 20 }} placeholder={'Adicionar novo Tempo Mínimo De Fundação Da Empresa'}/> : null }

                <FormGroup>
                  <Group>
                    <Label>Faturamento</Label>
                    <TextField as='select' name={'faturamento'}>
                      { callDataField('faturamento') }
                    </TextField>
                  </Group>
                  {!addNew.includes('faturamento')
                    ? <AddButton onClick={ () => handleAddNew('faturamento') }>
                    <IoAdd />
                  </AddButton>
                    : <AddButton onClick={ () => handleAddNew('faturamento') }>
                      <IoRemove/>
                    </AddButton>}
                </FormGroup>
                {addNew.includes('faturamento') ? <TextField name={'faturamento'} style={{ marginTop: 20 }} placeholder={'Adicionar novo faturamento'}/> : null }

                <FormGroup>
                  <Group>
                    <Label>Faturamento Minimo Mensal</Label>
                    <TextField as='select' name={'faturamentoMinimoMensal'}>
                      { callDataField('faturamentoMinimoMensal') }
                    </TextField>
                  </Group>
                  {!addNew.includes('faturamentoMinimoMensal')
                    ? <AddButton onClick={ () => handleAddNew('faturamentoMinimoMensal') }>
                    <IoAdd />
                  </AddButton>
                    : <AddButton onClick={ () => handleAddNew('faturamentoMinimoMensal') }>
                      <IoRemove/>
                    </AddButton>}
                </FormGroup>
                {addNew.includes('faturamentoMinimoMensal') ? <TextField name={'faturamentoMinimoMensal'} style={{ marginTop: 20 }} placeholder={'Adicionar novo faturamento Minimo Mensal'}/> : null }

                <FormGroup>
                  <Group>
                    <Label>Percentual De Garantia</Label>
                    <TextField as='select' name={'percentualDeGarantia'}>
                      { callDataField('percentualDeGarantia') }
                    </TextField>
                  </Group>
                  {!addNew.includes('percentualDeGarantia')
                    ? <AddButton onClick={ () => handleAddNew('percentualDeGarantia') }>
                    <IoAdd />
                  </AddButton>
                    : <AddButton onClick={ () => handleAddNew('percentualDeGarantia') }>
                      <IoRemove/>
                    </AddButton>}
                </FormGroup>
                {addNew.includes('percentualDeGarantia') ? <TextField name={'percentualDeGarantia'} style={{ marginTop: 20 }} placeholder={'Adicionar novo Percentual De Garantia'}/> : null }

                <FormGroup>
                  <Group>
                    <Label>Valor Minimo de Crédito</Label>
                    <TextField as='select' name={'valorMinimoDeCredito'}>
                      { callDataField('valorMinimoDeCredito') }
                    </TextField>
                  </Group>
                  {!addNew.includes('valorMinimoDeCredito')
                    ? <AddButton onClick={ () => handleAddNew('valorMinimoDeCredito') }>
                    <IoAdd />
                  </AddButton>
                    : <AddButton onClick={ () => handleAddNew('valorMinimoDeCredito') }>
                      <IoRemove/>
                    </AddButton>}
                </FormGroup>
                {addNew.includes('valorMinimoDeCredito') ? <TextField name={'valorMinimoDeCredito'} style={{ marginTop: 20 }} placeholder={'Adicionar novo Valor Minimo de Crédito'}/> : null }

                <FormGroup>
                  <Group>
                    <Label>Valor Maximo De Crédito</Label>
                    <TextField as='select' name={'valorMaximoDeCredito'}>
                      { callDataField('valorMaximoDeCredito') }
                    </TextField>
                  </Group>
                  {!addNew.includes('valorMaximoDeCredito')
                    ? <AddButton onClick={ () => handleAddNew('valorMaximoDeCredito') }>
                    <IoAdd />
                  </AddButton>
                    : <AddButton onClick={ () => handleAddNew('valorMaximoDeCredito') }>
                      <IoRemove/>
                    </AddButton>}
                </FormGroup>
                {addNew.includes('valorMaximoDeCredito') ? <TextField name={'valorMaximoDeCredito'} style={{ marginTop: 20 }} placeholder={'Adicionar novo Valor Maximo De Crédito'}/> : null }

                <FormGroup>
                  <Group>
                    <Label>Formato De Recebimento</Label>
                    <TextField as='select' name={'formatoDeRecebimento'}>
                      { callDataField('formatoDeRecebimento') }
                    </TextField>
                  </Group>
                  {!addNew.includes('formatoDeRecebimento')
                    ? <AddButton onClick={ () => handleAddNew('formatoDeRecebimento') }>
                    <IoAdd />
                  </AddButton>
                    : <AddButton onClick={ () => handleAddNew('formatoDeRecebimento') }>
                      <IoRemove/>
                    </AddButton>}
                </FormGroup>
                {addNew.includes('formatoDeRecebimento') ? <TextField name={'formatoDeRecebimento'} style={{ marginTop: 20 }} placeholder={'Adicionar novo Formato De Recebimento'}/> : null }

                <FormGroup>
                  <Group>
                    <Label>Revisão</Label>
                    <TextField as='select' name={'revisao'}>
                      { callDataField('revisao') }
                    </TextField>
                  </Group>
                  {!addNew.includes('revisao')
                    ? <AddButton onClick={ () => handleAddNew('revisao') }>
                    <IoAdd />
                  </AddButton>
                    : <AddButton onClick={ () => handleAddNew('revisao') }>
                      <IoRemove/>
                    </AddButton>}
                </FormGroup>
                {addNew.includes('revisao') ? <TextField name={'revisao'} style={{ marginTop: 20 }} placeholder={'Adicionar nova Revisão'}/> : null }

              <FormGroup>
                <Group>
                  <Label>Setores não atendidos com sacado</Label>
                  <TextArea name={'setoresNaoAtendidosComoSacado'} />
              </Group>
              </FormGroup>

              <FormGroup>
                <Group>
                  <Label>Criterios para analise</Label>
                  <TextArea name={'criteriosParaAnalise'} />
                </Group>
              </FormGroup>

              <FormGroup>
                <Group>
                  <Label>Pontos de Atenção</Label>
                  <TextArea name={'pontosDeAtencao'} />
                </Group>
              </FormGroup>

              <FormGroup>
                <Group>
                  <Label>Observações</Label>
                  <TextArea name={'observacoes'} />
                </Group>
              </FormGroup>

              <FormButton style={{ backgroundColor: 'white', color: 'black' }}>
                Salvar Alterações
              </FormButton>
              <FormButton style={{ backgroundColor: 'red' }}>
                Cancelar
              </FormButton>
            </Form>
          </Formik>
            </Column>
          </Wrap>
       </Container>

        } />
  )
}
export default EditMatriz
