import { Link } from 'react-router-dom'
import styled from 'styled-components'
import theme from '../../../../Contants/theme'

export const Container = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
`

export const Wrap = styled.div`
 width: 100%;
 height: 100%;
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 background-color: ${theme.COLORS.PRIMARY_700};
 padding: 1% 0% 1% 0%;
 border-radius: 0px;
 overflow-x: hidden;
`

export const Column = styled.div<{ isTitle?: boolean }>`
  width: 100%;
  display: flex;
  padding: 0px 1%;
  flex-direction: ${props => props.isTitle ? 'row' : 'column'};
  align-items: ${props => props.isTitle ? 'center' : 'flex-start'};
  gap: ${props => props.isTitle ? '20px' : '0px'};
  border-right: ${props => props.isTitle ? 'none' : '1px solid #414141'};
  overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 0px;
    }
`
export const Title = styled.h4`
`
export const WrapTitle = styled.div`
width: 100%;
height: 100%;
display: flex;
padding-top: 30px;
flex-direction: row;
justify-content: center;
align-items: center;
background-color: ${theme.COLORS.PRIMARY_700};
`
export const Detail = styled.div`
  width: 50%;
  height: 1px;
  background-color: ${theme.COLORS.SECONDARY_800};
`
export const NameInput = styled.input.attrs({ type: 'search' })`
  width: 90%;
  height: 50px;
  background-color: ${theme.COLORS.PRIMARY_700};
  border: 2px solid ${theme.COLORS.BORDER_SECONDARY};
  border-radius: 100px;
  padding-left: 20px;
  color: #fff;
  font-size: 16px;
`

export const DescriptionInput = styled.textarea`
  width: 90%;
  height: 200px;
  background-color: ${theme.COLORS.PRIMARY_700};
  border: 2px solid ${theme.COLORS.BORDER_SECONDARY};
  border-radius: 10px;
  padding-left: 20px;
  color: #fff;
  font-size: 16px;
`
export const Label = styled.label`
  font-size: 16px;
  font-weight: 600;
  margin-top: 50px;
  margin-bottom: 20px;
`
export const List = styled.ul`
  padding: 0;
  list-style-position: initial;
  list-style-type: none;
`
export const Item = styled.li`
  width: 100%;
  left:0;
  gap: 20px;
  padding: 0 2%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  font-size: 18px;
  &:hover{
    background-color: ${theme.COLORS.PRIMARY_800};
    cursor: pointer;
  }
`
export const Check = styled.input.attrs({ type: 'checkbox' })`
`

export const Icon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.COLORS.TEXT_SECONDARY};

  &:hover{
    color: #fff;
    background-color: ${theme.COLORS.BORDER_PRIMARY};
    cursor: pointer;
  }
`

export const FilterBar = styled.div`
  width: 100%;
  padding: 0px 0px 30px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  backgroud-color: red;
`

export const SearchBar = styled.input.attrs({ type: 'search' })`
  width: 90%;
  height: 50px;
  background-color: ${theme.COLORS.PRIMARY_800};
  border: 2px solid ${theme.COLORS.BORDER_SECONDARY};
  border-radius: 100px;
  padding-left: 20px;
  color: #fff;
  font-size: 16px;
`
export const AddButton = styled.button`
  width: 40%;
  height: 50px;
  border-radius: 100px;
  border: none;
  background-color: ${theme.COLORS.SECONDARY_800};
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;

  &:hover{
    cursor: pointer;
  }
`
export const Permission = styled.div<{ isSelected: boolean }>`
  width: 45%;
  height: 50px;
  border-radius: 10px;
  background-color: ${(props) => props.isSelected ? theme.COLORS.CONCLUDED_900 : theme.COLORS.PRIMARY_800};
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:hover{
    background-color: ${(props) => props.isSelected ? theme.COLORS.CONCLUDED_800 : theme.COLORS.PRIMARY_600};
    cursor: pointer;
  }
`
