/* eslint-disable n/handle-callback-err */
/* eslint-disable array-callback-return */
/* eslint-disable no-case-declarations */
import { useEffect, useState } from 'react'
import { Container, Wrap, Column, Icon, FilterBar, SearchBar, DescriptionInput, Label, Item, List, Check, Title, WrapTitle, Detail, HourCard, Card, CardHeader, CardEdit, CardHour, CardBody, Selector, TextField, InputNumber, Button, SaveButton } from './styles'
import { IoCalendarClearOutline, IoClose } from 'react-icons/io5'
import dataService from '../../services/data.service'
import Header from '../../components/Header'
import theme from '../../Contants/theme'
import { Form, Formik } from 'formik'
import moment from 'moment'

const TaskManager = () => {
  interface Data {
    name: string
    time: string
  }
  const [data, setData] = useState<any[]>([])
  const [activeIndex, setActiveIndex] = useState<number>()
  const [period, setPeriod] = useState('')
  const [limit, setLimit] = useState<any>(0)
  const [selecteds, setSelecteds] = useState<string[]>([])
  const timestamps: string[] = []
  const days: string[] = []
  moment.locale('pt-br')
  //  const weekDays: string[] = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sabádo']
  const weekDays = moment.weekdays()
  const generateTimestamps = () => {
    for (let hour = 0; hour < 24; hour++) {
      timestamps.push(`${String(hour).padStart(2, '0')}:00`)
    }
    return timestamps
  }
  generateTimestamps()

  const generateDays = () => {
    for (let day = 1; day <= 31; day++) {
      days.push(`${String(day)}`)
    }
    return timestamps
  }
  generateDays()

  const callDataSelector = (period: string[]) => {
    return period.map((item, index) => {
      const isActiveTest = selecteds.includes(item)
      return (<Selector style={{ }} key={index} isSelected={ isActiveTest } onClick={(e) => { handleHour(e.currentTarget.innerText) }}>{item}</Selector>)
    })
  }

  const SelectTime = (period: string) => {
    switch (period) {
      case 'Diario':
        return callDataSelector(timestamps)
      case 'Mensal':
        return callDataSelector(days)
      case 'Semanal':
        return callDataSelector(weekDays)
    }
  }

  useEffect(() => {
    dataService.getTask()
      .then((res: any) => {
        setData(res)
      })
      .catch((error: any) => console.log(error))
    generateTimestamps()
  }, [])

  const cronConverterToDate = (data: string) => {
    let period
    const formated = data.split(' ')
    const comparator = '*'
    switch (typeof data === 'string') {
      case formated[1] !== comparator:
        period = 'Diário'
        break
      case formated[2] !== comparator:
        period = 'Semanal'
        break
      case formated[3] !== comparator:
        period = 'Mensal'
        break
    }

    const formatToQuantity = data.replace(/[0*]/g, '').split(',')
    const quantity = formatToQuantity.length

    return { period, quantity }
  }

  console.log(`${
    // eslint-disable-next-line no-sequences, @typescript-eslint/restrict-template-expressions
    moment.locale('pt-br'),
    moment.weekdays(true, 2)
  }`)

  const setDateReturn = (time: string, period: string) => {
    const times: string[] = []
    let quantity = 0
    switch (period) {
      case 'Diario':
        const timeDiario = time.replace(/\s/g, '').replace(/0+(\d)/g, '$1').replace(/[*]/g, '').split('-')
        quantity = timeDiario.length
        const formatedTime = timeDiario.filter((item) => item !== '*')
        formatedTime.map((item) => {
          return times.push(`${item}:00`)
        })
        break
      case 'Semanal':
        const timeSemanal = time[10].split('-')
        quantity = timeSemanal.length
        timeSemanal.map((item) => {
          moment.locale('pt-br')
          return times.push(moment.weekdays(true, item).toString())
        })
        break
      case 'Mensal':
        const timeMensal = time[6].split(',')
        quantity = timeMensal.length
        timeMensal.map((item) => {
          return times.push(item)
        })
        break
    }
    return {
      time: times,
      quantity
    }
  }

  const cronConverter = (timestamp: string, period: string) => {
    let cronTime: string = ''
    const formated = timestamp.replace(/:00/g, '')
    console.log('timestamp:', timestamp, 'formated:', formated)
    switch (period) {
      case 'Diario':
        cronTime = `0 0 ${formated.replace(',', '-')} * * *`
        break
      case 'Semanal':

        const cronDays: number[] = []
        const days = timestamp.split(',')
        for (const item of days) {
          moment.locale('pt-br')
          console.log(moment(item, 'dddd').week() + 1)
          cronDays.push(moment(item, 'dddd').week() + 1)

        //  const cronDays: number[] = []
        //  const days = timestamp.split(',')
        //  for (const item of days) {
        //  console.log(item)
        //  switch (item.toString()) {
        //    case 'Domingo':
        //      cronDays.push(1)
        //      break
        //    case 'Segunda-Feira':
        //      console.log('estou aqui na segunda')
        //      cronDays.push(2)
        //      break
        //    case 'Terça-Feira':
        //      console.log('estou aqui na terça')
        //      cronDays.push(3)
        //      break
        //    case 'Quarta-Feira':
        //      cronDays.push(4)
        //      break
        //    case 'Quinta-Feira':
        //      cronDays.push(5)
        //      break
        //    case 'Sexta-Feira':
        //      cronDays.push(6)
        //      break
        //    case 'Sabádo':
        //      cronDays.push(7)
        //      break
        //  }
        }
        const formatedDays = cronDays.toString().replace(/,/g, '-')
        console.log(days)
        console.log(formatedDays)
        cronTime = `0 0 0 * * ${formatedDays}`
        break
      case 'Mensal':
        cronTime = `0 0 0 ${formated.replace(',', '-')} * *`
        break
    }
    return cronTime
  }
  const handleHour = (value: string) => {
    if (!selecteds.includes(value)) {
      if (selecteds.length <= (limit - 1)) {
        setSelecteds(arr => [...arr, value])
      }
    } else {
      setSelecteds(arr => arr.filter((item: string) => item !== value))
    }
  }

  const handleEditButton = (index: number) => {
    setActiveIndex(index)
  }

  const handleCancelButton = () => {
    setActiveIndex(-1)
    setPeriod('')
    setLimit(0)
    setSelecteds([])
  }

  return (
        <Header content={
          <Container>
          <WrapTitle>
            <Column isTitle={true}>
              <Title>
                Agendamento de atualização automática
              </Title>
              <Detail />
            </Column>
          </WrapTitle>
            {
              data.map((task: any, index: number) => {
                console.log(task)
                const result = setDateReturn(task.time, 'Diario')
                return (
                <Wrap key={index}>
              <Column>
             <Label>{task.name}</Label>
                <Card>
                 <CardHeader>
                   <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                     <div>
                       <IoCalendarClearOutline color={theme.COLORS.SECONDARY_800} size={150} />
                     </div>
                     <div style={{ display: 'flex', flexDirection: 'column' }}>
                       Periódo:
                       <h2>{task.period}</h2>
                     </div>
                   </div>
                   <div>
                     Quantidade:
                     <h1>{ result.quantity }x</h1>
                   </div>
                   <div>
                     Horarios:
                     <div>
                      {result.time.map((item, index) => {
                        return (
                        <HourCard key={index} isSelected={true}>
                         {item}
                       </HourCard>
                        )
                      })
                      }
                     </div>
                   </div>
                 </CardHeader>
                 { activeIndex === index
                   ? <CardEdit>
                   <Formik
                     initialValues={{
                       period: ''
                     }}
                     onSubmit={async (values) => {
                       values.period = period
                       // await dataService.updateTask(task.id, task.name, cronConverter(selecteds.toString(), values.period), period)
                       //   .then(() => {
                       //     window.location.reload()
                       //   })
                       //   .catch((error) => {
                       //     alert('Erro ao atualizar')
                       //   })
                       console.log('Period:', values, 'limit:', limit, 'cronTime:', cronConverter(selecteds.toString(), values.period), 'Hours:', selecteds.toString().replace(/^0|:/g, ''))
                     } }>
                     <Form>
                       <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                         <div style={{ display: 'flex', flexDirection: 'column', gap: 10, width: '50%' }}>
                           <Label>Periodo:</Label>
                           <TextField as='select' name='period' id='period' onChange={(e) => { setPeriod(e.target.value) } }>
                             <option></option>
                             <option value={'Diario'}>Diário</option>
                             <option value={'Semanal'}>Semanal</option>
                             <option value={'Mensal'}>Mensal</option>
                           </TextField>
                         </div>
                         <div style={{ display: 'flex', flexDirection: 'column', gap: 10, width: '50%' }}>
                           <Label>Quantidade:</Label>
                           <InputNumber
                             name={'quantity'} id='quantity'
                             onChange={(e) => setLimit(e.target.value)} />
                         </div>
                       </div>
                       <Label>Horário:</Label>
                       <CardHour>
                         <CardBody>
                           {SelectTime(period)}
                         </CardBody>
                       </CardHour>
                       <WrapTitle style={{ justifyContent: 'center', alignItems: 'center', paddingBottom: 20, paddingTop: 20 }}>
                         <Button cancel={true} onClick={() => handleCancelButton()}>
                           Cancelar
                         </Button>
                         <SaveButton>
                           Salvar
                         </SaveButton>
                       </WrapTitle>
                     </Form>
                   </Formik>
                 </CardEdit>
                   : <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingBottom: 20, paddingTop: 20 }}>
                    <Button onClick={() => handleEditButton(index)}>
                         Editar
                     </Button>
                    </div>
                  }
               </Card>
              </Column>
              </Wrap>
                )
              })
            }
       </Container>

        } />
  )
}
export default TaskManager
