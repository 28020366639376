import { Link } from 'react-router-dom'
import styled from 'styled-components'
import theme from '../../../Contants/theme'
import bg from '../../../assets/images/background/background2.png'
import { Field } from 'formik'

export const Body = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background-image: url(${bg});
  background-color: #000;
  background-position: center bottom;
  background-repeat: no-repeat;
  ;
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  heigth: 40px;
`

export const Container = styled.div`
width: 100%;
height: 100%;
border-radius: 20px;
display: flex;
align-items: flex-start;
justify-content: flex-start;
flex-direction: column;
border-radius: 50px;

@media (min-width: 1200px){
  width: 80%;
  height: 95%;
}

@media (min-width: 2560px){
  width: 50%;
  height: 70%;
}

`

export const Area = styled.div`
width: 100%;
height: 100%;
background-color: ${theme.COLORS.PRIMARY_900};
display: flex;
flex-direction: row;
margin-top: 50px;
padding-top: 20px;
align-items: flex-start;
justify-content: center;
border-radius: 20px 20px 0px 0px;
`

export const FormBox = styled.div`
width: 100%;
height: 100%;
background-color: ${theme.COLORS.PRIMARY_900};
display: flex;
margin-bottom: 20px;
justify-content: flex-start;
border-radius: 0px 0px 20px 20px;
`

export const UserInput = styled(Field)`
width: 100%;
height: 40px;
border-radius: 100px;
background-color: ${theme.COLORS.PRIMARY_700};
border: none;
margin-top: 5px;
margin-bottom: 5px;
color: #fff;
font-size: 18px;
padding-left: 20px;
`
export const PassInput = styled(Field)`
width: 100%;
height: 40px;
margin-top: 5px;
margin-bottom: 5px;
border-radius: 100px;
background-color: ${theme.COLORS.PRIMARY_700};
border: none;
color: #fff;
font-size: 18px;
padding-left: 20px;
`
export const Label = styled.label`
font-size: 14px;
font-weight: 700;
color: #fff;
margin-top: 5px;
margin-bottom: 10px;
`
export const Button = styled.button`
width: 100%;
height: 50px;
border-radius: 100px;
background-color: ${theme.COLORS.SECONDARY_800};
border: none;
margin-top: 10px;
margin-bottom: 20px;
color: white;
font-size: 20px;
font-weight: 700;
display: flex;
justify-content: center;
align-items: center;
text-decoration: none;
 &:hover{
  cursor: pointer;
  background-color: ${theme.COLORS.SECONDARY_900};
 }
`

export const Title = styled.h2`
@media (min-width: 1200px){
  font-size: 20px;
}

@media (min-width: 2560px){
  font-size: 30px;
}
`

export const Logo = styled.div`
`

export const ForgetPass = styled(Link)`
color: white;
width: 65%;
font-size: 16px;
font-weight: 500;
display: flex;
justify-content: flex-end;
text-decoration: none;
`

export const ErrorMessage = styled.div`
color: red;
font-size: 16px;
font-weight: 500;
display: flex;
justify-content: center;
text-decoration: none;
`

export const Permissions = styled.div`
width: 100%;
padding: 10px;
border-radius: 20px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`

export const Item = styled.div<{ isSelected: boolean }>`
  width: 200px;
  height: 40px;
  border-radius: 20px;
  background-color: ${(props) => props.isSelected ? theme.COLORS.CONCLUDED_900 : theme.COLORS.PRIMARY_700};
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:hover{
    background-color: ${(props) => props.isSelected ? theme.COLORS.CONCLUDED_800 : theme.COLORS.PRIMARY_800};
    cursor: pointer;
  }
`

export const Horizontal = styled.div`
display: flex;
flex-direction: row;
gap: 20px;
`
