import React from 'react'
import './spinner.css'
const LoadingSpinner = () => {
  return (
    <div className="spinner-container">
        <div className="loading-spinner">
        </div>
    </div>
  )
}
export default LoadingSpinner
