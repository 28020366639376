/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-base-to-string */
import { useEffect, useState } from 'react'
import { IoCheckmarkCircle, IoCloseCircle } from 'react-icons/io5'
import Header from '../../components/Header'
import theme from '../../Contants/theme'
import { Container, Message, SubMessage } from './styles'
import LoadingSpinner from '../../components/LoadingSpinner'
import dataService from '../../services/data.service'
import 'moment/locale/pt-br'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import updateService from '../../services/update.service'
import { io } from 'socket.io-client'
import { env } from 'process'
import { API_URL } from '../../services/api_url'

const DataUpdate = () => {
  const params = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [message, setMessage] = useState(`Atualizando dados do ${params.id}...`)
  const [subMessage, setSubMessage] = useState('Aguarde isso pode levar alguns minutos.')
  const [isAdmin, setIsAdmin] = useState(false)
  const [status, setStatus] = useState<any>()

  const socket = io(API_URL)
  useEffect(() => {
    socket.on('status', (result) => {
      setStatus(result)
    })
    return () => {
      socket.off('connect')
      socket.off('status')
    }
  })

  useEffect(() => {
    checkStatus()
  }, [status])

  function checkStatus () {
    if (status) {
      for (const key in status) {
        if (key === params.id && status[key] === 'updating') {
          setMessage('Atualizando dados...')
          setSubMessage('Aguarde isso pode levar alguns minutos.')
          setIsLoading(true)
        } else if (key === params.id && status[key] === 'success') {
          setMessage('Dados Atualizados com sucesso')
          setSubMessage(`Ultima atualização: ${moment().locale('pt-br').format('LLLL')}`)
          setIsLoading(false)
        } else if (key === params.id && status[key] === 'error') {
          setMessage('Erro ao atualizar dados')
          setSubMessage('...')
          setIsLoading(false)
        }
      }
    }
  }

  useEffect(() => {
    const handleUpdateData = async () => {
      setMessage('Atualizando dados...')
      setSubMessage('Aguarde isso pode levar alguns minutos.')
      setIsLoading(true)

      let job

      switch (params.id) {
        case 'Bitrix' :
          job = updateService.Bitrix()
          break
        case 'Asaas' :
          job = updateService.Asaas()
          break
        case 'BomControle' :
          job = updateService.BomControle()
          break
        case 'Grafeno' :
          job = updateService.Grafeno()
          break
      }

      if (job) {
        await job
          .then(result => {
            console.log(result)
            if (result === 200) {
              checkStatus()
            } else {
              setMessage('Erro ao atualizar dados')
              setSubMessage('...')
              setIsLoading(false)
            }
          })
      }
    }

    handleUpdateData()
  }, [])
  return (
    <Header content={
      <Container>
        {message !== 'Erro ao atualizar dados' ? <IoCheckmarkCircle size={150} color={theme.COLORS.PRIMARY_600}/> : <IoCloseCircle size={250} color={theme.COLORS.PRIMARY_600}/>}
        <div style={{ textAlign: 'center' }}>
        <Message>{message}</Message>
        <SubMessage>{subMessage}</SubMessage>
        </div>
        { isLoading
          ? <LoadingSpinner/>
          : null
        }
      </Container>

    }/>
  )
}
export default DataUpdate
