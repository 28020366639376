/* eslint-disable @typescript-eslint/no-misused-promises */
import { Area, Body, Button, Container, UserInput, PassInput, Title, Label, Logo, Form, ForgetPass, ErrorMessage } from './styles'
import logo from '../../../assets/images/logo-teddy/logo2x.png'
import { redirect, useNavigate } from 'react-router-dom'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { useAuth } from '../../../contexts/auth'
import { useState } from 'react'
import authService from '../../../services/auth.service'

const RequestChangePassword = () => {
  const [email, setEmail] = useState('')
  const [token, setToken] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [nextStep, setNextStep] = useState(false)

  const handleSubmit = () => {
    if (password === confirmPassword) {
      setIsLoading(true)
      authService.confirmRestPassword(email, token, password).then(res => {
        alert('Senha alterada com sucesso! Clique em voltar para acessar a página de login.')
      }).catch((error) => {
        console.log(error)
        setError('Erro, tente novamente')
        setIsLoading(false)
      })
    } else {
      setIsLoading(false)
      setError('As senhas não conferem ')
    }
  }

  const handleEmailStep = (e: any) => {
    e.preventDefault()
    authService.restPassword(email).then(res => {
      if (email !== '') {
        setError('')
        setNextStep(true)
        alert('Email enviado! Verifique sua caixa de e-mail.')
      }
      setError('Por favor, digite um e-mail válido.')
    }).catch((error) => {
      console.log(error)
      setError('Erro, tente novamente')
      setIsLoading(false)
    })
  }

  return (
    <Body>
     <Container>
        <Area>
            <Logo>
                <img src={logo}/>
            </Logo>
        </Area>
        { nextStep
          ? <Form>
          <Title>Recuperação de senha</Title>
          <Label>Codigo de verificação</Label>
            <UserInput
              value={token}
              onChange={ e => setToken(e.target.value)}
            />
          <Label>Nova Senha</Label>
            <PassInput
              value={password}
              onChange={ e => setPassword(e.target.value)}
            />
          <Label>Confirmar Senha</Label>
            <PassInput
              value={confirmPassword}
              onChange={ e => { setConfirmPassword(e.target.value); e.target.value === password ? setError('') : setError('As senhas não conferem') }}
            />
          {error !== '' && <ErrorMessage>{error}</ErrorMessage>}

          {!isLoading
            ? <>
            <Button onClick={async () => handleSubmit()}>
            Enviar
          </Button>
          <ForgetPass to="/login">Voltar</ForgetPass>
          </>
            : <LoadingSpinner />
        }
        </Form>
          : <Form>
            <Title>Recuperação de senha</Title>
            <Label>Digite seu e-mail</Label>
            <UserInput
              value={email}
              onChange={ e => setEmail(e.target.value)}
            />
            {error !== '' && <ErrorMessage>{error}</ErrorMessage>}

            {!isLoading
              ? <>
              <Button onClick={async (e) => handleEmailStep(e)}>
              Enviar
             </Button>
             <ForgetPass to="/login">Voltar</ForgetPass>
             </>
              : <LoadingSpinner />
            }
        </Form>
      }
     </Container>
     </Body>
  )
}
export default RequestChangePassword
