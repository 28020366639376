import { Area, Body, Button, Container, Title, Label, Logo, FormBody, ForgetPass, ErrorMessageStyled, Input } from './styles'
import logo from '../../../assets/images/logo-teddy/logo2x.png'
import { useNavigate } from 'react-router-dom'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { useAuth } from '../../../contexts/auth'
import { useState } from 'react'
import { Formik } from 'formik'

const Login = () => {
  const [isSubmitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  const context = useAuth()
  const handleSubmit = async () => {
    if (password && email) {
      setSubmitting(true)
      await context.Login(email, password)
        .then(() => {
          navigate('/')
        }).catch((e) => {
          setError('Usuário ou senha inválido')
          setSubmitting(false)
          // console.log(e)
        })
    } else {
      setError('Por favor, preencha corretamente.')
    }
  }

  return (
    <Body>
     <Container>
        <Area>
            <Logo>
                <img src={logo}/>
            </Logo>
        </Area>
        <Formik
          initialValues={{ email: '', password: '' }}
          validate={values => {
            const errors = {
              email,
              password,
              error
            }
            console.log(values)
            if (!values.email) {
              errors.email = 'O Email precisa ser preenchido.'
            }
            if (!values.password) {
              errors.password = 'A senha precisa ser preenchida.'
            }

            if (error !== '') {
              errors.error = error
            }
            return errors
          }}
          onSubmit = {(values) => {
            alert(values)
          }}>
          <FormBody>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <Label htmlFor="email">Email</Label>
              <Input
                type="email"
                name="email"
                value={email}
                onChange={ (e: any) => { setEmail(e.target.value) }}
                />
            </div>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <Label htmlFor="password">Password</Label>
              <Input
                type="password"
                name="password"
                value={password}
                onChange={ (e: any) => { setPassword(e.target.value) }}
              />
            </div>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            {!isSubmitting
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              ? <Button type='submit' onClick={() => { handleSubmit() }}>
              Entrar
             </Button>
              : <LoadingSpinner />
            }
            <ErrorMessageStyled>{error}</ErrorMessageStyled>
            </div>
          </FormBody>
        </Formik>
     </Container>
     </Body>
  )
}
export default Login
