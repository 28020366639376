import { AuthProvider } from './contexts/auth'
import Routes from './Routes'
import DefaultRoutes from './Routes/DefaultRoutes'

function App () {
  return (
    <AuthProvider>
      <DefaultRoutes />
    </AuthProvider>
  )
}

export default App
